
function Summary() {
    return (
        <>
        <div className="userccount">
  <div className="formpanel mt0" >
    <h5>Summary</h5>
    <div className="row">
      <div className="col-md-12">
        <form className="form" id="add_edit_profile_summary" method="POST" action="https://example.com/update-front-profile-summary/6">
          <input type="hidden" name="_token" defaultValue="iKL1rZ5zWgzIgVQHxSYM0f5tiP5o3R440JJNZqin" />
          <div className="form-body">
            <div id="success_msg" />
            <div className="formrow ">
              <textarea name="summary" className="form-control" id="summary" placeholder="Profile Summary" defaultValue={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla at enim quis tincidunt. Nulla condimentum dapibus efficitur. In massa felis, fringilla at urna vestibulum, mattis malesuada metus. Integer id lorem tortor. Pellentesque hendrerit sapien sit amet finibus pretium. Fusce eu sagittis orci. Quisque urna velit, facilisis interdum nisl nec, commodo tristique leo. Maecenas turpis augue, vulputate ac lorem in, euismod euismod tortor. Phasellus vitae lacinia est, ut porta leo. Morbi sit amet quam in risus gravida mattis. Suspendisse sodales massa et odio mollis, id ultricies ipsum semper. Duis pretium vestibulum dui at scelerisque."} />
              <span className="help-block summary-error" /> </div>
            <button type="button" className="btn btn-large btn-primary" onclick="submitProfileSummaryForm();">Update Summary <i className="fa fa-arrow-circle-right" aria-hidden="true" /></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

        </>
    )
}
export default Summary