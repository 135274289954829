import "./App.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/all.min.css";
import "../src/assets/css/main.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/css/custom.css";
import { Navigate, Route, Routes } from "react-router-dom";
import CandidatePage from "./page/Candidate/Index";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import DashBoardPage from "./page/dashboard/Index";
import PageSearch from "./components/common/pageSearch/PageSearch";
import EditProfilePage from "./page/editProfile/Index";
import ViewPublicProfilePage from "./page/viewPublicProfile";
import FavouriteJobsPage from "./page/favouriteJobs";
import MyJobAlertsPage from "./page/myJobAlerts";
import MyJobApplicationPage from "./page/myJobApplication";
import ViewDetails from "./components/myJobApplication/viewDetails/ViewDetails";
import SkillsRequired from "./components/common/skillsRequired/SkillsRequired";
import CompanyDetails from "./components/common/companyDetails/CompanyDetails";
import ViewDetailReportAbuse from "./components/myJobApplication/viewDetails/viewDetailReportAbuse/ViewDetailReportAbuse";
import EmailFriend from "./components/myJobApplication/viewDetails/emailFriend/EmailFriend";

import MyMessagePage from "./page/myMessage/Index";
import MyFollowingPage from "./page/myFollowings/Index";

function App() {
  return (
    <>
      <Header />
      <PageSearch />
      <Routes>
        <Route path="/" element={<Navigate to={"Candidate"} />} />
        <Route path="/Candidate" element={<CandidatePage />}>
          <Route path="" element={<DashBoardPage />} />
          <Route path="my-profile" element={<EditProfilePage />} />
          <Route path="my-messages" element={<MyMessagePage />} />
          <Route path="my-followings" element={<MyFollowingPage />} />

          <Route path="my-favourite-jobs" element={<FavouriteJobsPage />} />
          <Route path="my-alerts" element={<MyJobAlertsPage />} />
          <Route
            path="my-job-applications"
            element={<MyJobApplicationPage />}
          />
        </Route>

        <Route
          path="/view-public-profile"
          element={<ViewPublicProfilePage />}
        />
        <Route path="/ios-developer-2" element={<ViewDetails />} />
        <Route path="/job_skill" element={<SkillsRequired />} />
        <Route
          path="/company/abaris-softech-pvt-ltd-9"
          element={<CompanyDetails />}
        />
        <Route path="/report-abuse" element={<ViewDetailReportAbuse />} />
        <Route path="/email-to-friend" element={<EmailFriend />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
