import MyFollowings from "../../components/myFollowings/MyFollowing";

function MyFollowingPage() {
  return (
    <>
      <MyFollowings />
    </>
  );
}
export default MyFollowingPage;
