import { Link } from "react-router-dom";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const jobData = {
  applicationData: [
    {
      id: "1",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "IOS Developer",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "Full Time/Permanent",
      day: "First Shift (Day)",
      station: "Auburn",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
    {
      id: "2",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "Project Manager",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "Full Time/Permanent",
      day: "First Shift (Day)",
      station: "Kaneohe Station",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
    {
      id: "3",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "Full Stack Designer",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "Full Time/Permanent",
      day: "Third Shift (Night)",
      station: "al-Ahmadi",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
    {
      id: "4",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "Full Stack Developer Required",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "Contract",
      day: "Second Shift (Afternoon)",
      station: "Bessemer",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
    {
      id: "5",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "Graphic Designer Required",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "Contract",
      day: "Second Shift (Afternoon)",
      station: "Bessemer",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
    {
      id: "6",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "SEO Expert",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "Contract",
      day: "Second Shift (Afternoon)",
      station: "Casas Adobes",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
  ],
};

function CompanyDetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="listpgWraper">
        <div className="container">
          {/* Job Header start */}
          <div className="job-header">
            <div className="jobinfo">
              <div className="row">
                <div className="col-md-8 col-sm-8">
                  {/* Candidate Info */}
                  <div className="candidateinfo">
                    <div className="userPic">
                      <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
                        <img
                          src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                          alt="Abaris Softech Pvt Ltd"
                          title="Abaris Softech Pvt Ltd"
                        />
                      </a>
                    </div>
                    <div className="title">Abaris Softech Pvt Ltd</div>
                    <div className="desi">Information Technology</div>
                    <div className="loctext">
                      <i className="fa fa-history" aria-hidden="true" />
                      Member Since, Jul 27, 2018
                    </div>
                    <div className="loctext">
                      <i className="fa fa-map-marker" aria-hidden="true" />
                      Namberdar Estate, 2nd Floor, B 98, Kailash Hills, New
                      Friends Colony, New Delhi, Delhi 110025, INDIA
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  {/* Candidate Contact */}
                  <div className="candidateinfo">
                    <div className="loctext">
                      <i className="fas fa-phone" aria-hidden="true" />{" "}
                      <a href="tel:+918851746286">+918851746286</a>
                    </div>
                    <div className="loctext">
                      <i className="fas fa-envelope" aria-hidden="true" />{" "}
                      <a href="mailto:hr@abaris.in">hr@abaris.in</a>
                    </div>
                    <div className="loctext">
                      <i className="fas fa-globe" aria-hidden="true" />{" "}
                      <a href="https://www.abarissoftech.com" target="_blank">
                        https://www.abarissoftech.com
                      </a>
                    </div>
                    <div className="cadsocial">
                      {" "}
                      <a
                        href="https://www.facebook.com/Abaris-Softech-107870812076527"
                        target="_blank"
                      >
                        <i
                          className="fa fa-facebook-square"
                          aria-hidden="true"
                        />
                      </a>
                      <a href="https://twitter.com/" target="_blank">
                        <i
                          className="fa fa-twitter-square"
                          aria-hidden="true"
                        />
                      </a>
                      <a href="https://www.linkedin.com/" target="_blank">
                        <i
                          className="fa fa-linkedin-square"
                          aria-hidden="true"
                        />
                      </a>
                      <a href="https://plus.google.com/" target="_blank">
                        <i
                          className="fa fa-google-plus-square"
                          aria-hidden="true"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/abaris_sof_tech/"
                        target="_blank"
                      >
                        <i
                          className="fa fa-pinterest-square"
                          aria-hidden="true"
                        />
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="jobButtons">
              {" "}
              <a
                href="https://hsalghanimdelivery.com/add-to-favourite-company/abaris-softech-pvt-ltd-9"
                className="btn"
              >
                <i className="fa fa-floppy-o" aria-hidden="true" /> Add to
                Favourite
              </a>{" "}
              <Link to="/report-abuse" className="btn report">
                <i className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
                Report Abuse
              </Link>{" "}
              <a href="#" className="btn" onClick={handleShow}>
                <i className="fa fa-envelope" aria-hidden="true" /> Send Message
              </a>{" "}
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Send Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      id="message"
                      cols={10}
                      rows={7}
                      defaultValue={""}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary">Submit</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              {/* About Employee start */}
              <div className="job-header">
                <div className="contentbox">
                  <h3>About Company</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin fermentum condimentum mauris, non posuere urna
                    consectetur quis. Suspendisse semper eu eros eget convallis.
                    Etiam mattis blandit nulla, non venenatis risus varius vel.
                    Morbi fringilla dignissim elit id blandit. Pellentesque vel
                    luctus felis. Vestibulum eros nibh, consequat ut felis in,
                    vehicula lobortis quam. Duis diam mauris, convallis in risus
                    in, gravida hendrerit lacus. Donec euismod accumsan sem et
                    aliquam. Duis a velit eget urna mattis ultricies. Aliquam
                    nibh ipsum, aliquet nec sollicitudin non, fermentum nec
                    diam. Vestibulum ac urna vehicula, dapibus dui quis, aliquet
                    neque. Sed ac tristique purus. Vestibulum tempor, erat eu
                    porta tempor, erat ipsum cursus dui, eu tempus mauris leo id
                    mi. Sed ultrices sollicitudin vehicula. Proin in ullamcorper
                    massa.
                  </p>
                </div>
              </div>
              {/* Opening Jobs start */}
              <div className="relatedJobs">
                <h3>Job Openings</h3>
                <ul className="searchList">
                  {jobData.applicationData.map((item) => {
                    return (
                      <li key={item.id}>
                        <div className="row">
                          <div className="col-lg-9 col-md-8">
                            <div className="jobimg">
                              <a
                                href="https://hsalghanimdelivery.com/job/ios-developer-2"
                                title="IOS Developer"
                              >
                                {" "}
                                <img
                                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                                  alt="Abaris Softech Pvt Ltd"
                                  title="Abaris Softech Pvt Ltd"
                                />{" "}
                              </a>
                            </div>
                            <div className="jobinfo">
                              <h3>
                                <a
                                  href="https://hsalghanimdelivery.com/job/ios-developer-2"
                                  title="IOS Developer"
                                >
                                  {item.title}
                                </a>
                              </h3>
                              <div className="companyName">
                                <a
                                  href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                                  title="Abaris Softech Pvt Ltd"
                                >
                                  {item.companyName}
                                </a>
                              </div>
                              <div className="location">
                                <label
                                  className="fulltime"
                                  title="Full Time/Permanent"
                                >
                                  {item.shift}
                                </label>
                                <label
                                  className="partTime"
                                  title="First Shift (Day)"
                                >
                                  {item.day}
                                </label>
                                - <span>Auburn</span>
                              </div>
                            </div>
                            <div className="clearfix" />
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="listbtn">
                              <Link to="/ios-developer-2">
                                {item.showDetail}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <p>{item.detail}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              {/* Company Detail start */}
              <div className="job-header">
                <div className="jobdetail">
                  <h3>Company Detail</h3>
                  <ul className="jbdetail">
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Is Email Verified</div>
                      <div className="col-md-6 col-xs-6">
                        <span>No</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Total Employees</div>
                      <div className="col-md-6 col-xs-6">
                        <span>301-600</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Established In</div>
                      <div className="col-md-6 col-xs-6">
                        <span>2006</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Current jobs</div>
                      <div className="col-md-6 col-xs-6">
                        <span>6</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Google Map start */}
              <div className="job-header">
                <div className="jobdetail">
                  <iframe
                    src="https://maps.google.it/maps?q=&output=embed"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CompanyDetails;
