import cover from "../../assets/img/image/cover.png"
import profileImg from "../../assets/img/image/img-2.png"

import CurriculumVitae from "./curriculumVitae/Curriculumvitae";
import Summary from "./summary/Summary";


function AccountInformation() {
  return (
    <>
      <div className="userccount">
        <div className="formpanel mt0">
          {/* Personal Information */}
          <form
            method="POST"
            action="https://example.com/my-profile"
            acceptCharset="UTF-8"
            className="form"
            encType="multipart/form-data"
          >
            <input name="_method" type="hidden" defaultValue="PUT" />
            <input
              name="_token"
              type="hidden"
              defaultValue="iKL1rZ5zWgzIgVQHxSYM0f5tiP5o3R440JJNZqin"
            />
            <h5>Account Information</h5>
            <div className="row">
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Email</label>
                  <input
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    name="email"
                    type="text"
                    defaultValue="seeker@example.com"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Password</label>
                  <input
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    name="password"
                    type="password"
                    defaultValue
                  />
                </div>
              </div>
            </div>
            <hr />
            <h5>Personal Information</h5>
            <div className="row">
              <div className="col-md-6">
                <div className="formrow">
                  <label>Profile Image</label>
                  <img
                    src={profileImg}
                    style={{ maxHeight: 100 }}
                    alt
                    title
                  />{" "}
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow">
                  <div id="thumbnail" />
                  <label className="btn btn-default">
                    {" "}
                    Select Profile Image
                    <input
                      type="file"
                      name="image"
                      id="image"
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <h6>Cover Photo</h6>
            <div className="row">
              <div className="col-md-6">
                <div className="formrow">
                  {" "}
                  <img
                    src={cover}
                    style={{ maxHeight: 50 }}
                    alt
                    title
                  />{" "}
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow">
                  <div id="thumbnail_cover_image" />
                  <label className="btn btn-default">
                    {" "}
                    Select Cover Photo
                    <input
                      type="file"
                      name="cover_image"
                      id="cover_image"
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>First Name</label>
                  <input
                    className="form-control"
                    id="first_name"
                    placeholder="First Name"
                    name="first_name"
                    type="text"
                    defaultValue="Daniyal"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Midlle Name</label>
                  <input
                    className="form-control"
                    id="middle_name"
                    placeholder="Middle Name"
                    name="middle_name"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Last Name</label>
                  <input
                    className="form-control"
                    id="last_name"
                    placeholder="Last Name"
                    name="last_name"
                    type="text"
                    defaultValue="Khan"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Father Name</label>
                  <input
                    className="form-control"
                    id="father_name"
                    placeholder="Father Name"
                    name="father_name"
                    type="text"
                    defaultValue="Mr. Father"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Gender</label>
                  <select
                    className="form-control"
                    id="gender_id"
                    name="gender_id"
                  >
                    <option value>Select Gender</option>
                    <option value={1}>Female</option>
                    <option value={2} selected="selected">
                      Male
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Martial Status</label>
                  <select
                    className="form-control"
                    id="marital_status_id"
                    name="marital_status_id"
                  >
                    <option value>Select Marital Status</option>
                    <option value={1}>Divorced</option>
                    <option value={2}>Married</option>
                    <option value={3}>Separated</option>
                    <option value={4} selected="selected">
                      Single
                    </option>
                    <option value={5}>Widow/er</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Country</label>
                  <select
                    className="form-control"
                    id="country_id"
                    name="country_id"
                  >
                    <option value>Select Country</option>
                    <option value={1}>Afghanistan</option>
                    <option value={2}>Albania</option>
                    <option value={3}>Algeria</option>
                    <option value={4}>American Samoa</option>
                    <option value={5}>Andorra</option>
                    <option value={6}>Angola</option>
                    <option value={7}>Anguilla</option>
                    <option value={8}>Antarctica</option>
                    <option value={9}>Antigua And Barbuda</option>
                    <option value={10}>Argentina</option>
                    <option value={11}>Armenia</option>
                    <option value={12}>Aruba</option>
                    <option value={13}>Australia</option>
                    <option value={14}>Austria</option>
                    <option value={15}>Azerbaijan</option>
                    <option value={16}>Bahamas The</option>
                    <option value={17}>Bahrain</option>
                    <option value={18}>Bangladesh</option>
                    <option value={19}>Barbados</option>
                    <option value={20}>Belarus</option>
                    <option value={21}>Belgium</option>
                    <option value={22}>Belize</option>
                    <option value={23}>Benin</option>
                    <option value={24}>Bermuda</option>
                    <option value={25}>Bhutan</option>
                    <option value={26}>Bolivia</option>
                    <option value={27}>Bosnia and Herzegovina</option>
                    <option value={28}>Botswana</option>
                    <option value={29}>Bouvet Island</option>
                    <option value={30}>Brazil</option>
                    <option value={31}>British Indian Ocean Territory</option>
                    <option value={32}>Brunei</option>
                    <option value={33}>Bulgaria</option>
                    <option value={34}>Burkina Faso</option>
                    <option value={35}>Burundi</option>
                    <option value={36}>Cambodia</option>
                    <option value={37}>Cameroon</option>
                    <option value={38}>Canada</option>
                    <option value={39}>Cape Verde</option>
                    <option value={40}>Cayman Islands</option>
                    <option value={41}>Central African Republic</option>
                    <option value={42}>Chad</option>
                    <option value={43}>Chile</option>
                    <option value={44}>China</option>
                    <option value={45}>Christmas Island</option>
                    <option value={46}>Cocos (Keeling) Islands</option>
                    <option value={47}>Colombia</option>
                    <option value={48}>Comoros</option>
                    <option value={49}>Republic Of The Congo</option>
                    <option value={50}>Democratic Republic Of The Congo</option>
                    <option value={51}>Cook Islands</option>
                    <option value={52}>Costa Rica</option>
                    <option value={53}>Cote D'Ivoire (Ivory Coast)</option>
                    <option value={54}>Croatia (Hrvatska)</option>
                    <option value={55}>Cuba</option>
                    <option value={56}>Cyprus</option>
                    <option value={57}>Czech Republic</option>
                    <option value={58}>Denmark</option>
                    <option value={59}>Djibouti</option>
                    <option value={60}>Dominica</option>
                    <option value={61}>Dominican Republic</option>
                    <option value={62}>East Timor</option>
                    <option value={63}>Ecuador</option>
                    <option value={64}>Egypt</option>
                    <option value={65}>El Salvador</option>
                    <option value={66}>Equatorial Guinea</option>
                    <option value={67}>Eritrea</option>
                    <option value={68}>Estonia</option>
                    <option value={69}>Ethiopia</option>
                    <option value={70}>
                      External Territories of Australia
                    </option>
                    <option value={71}>Falkland Islands</option>
                    <option value={72}>Faroe Islands</option>
                    <option value={73}>Fiji Islands</option>
                    <option value={74}>Finland</option>
                    <option value={75}>France</option>
                    <option value={76}>French Guiana</option>
                    <option value={77}>French Polynesia</option>
                    <option value={78}>French Southern Territories</option>
                    <option value={79}>Gabon</option>
                    <option value={80}>Gambia The</option>
                    <option value={81}>Georgia</option>
                    <option value={82}>Germany</option>
                    <option value={83}>Ghana</option>
                    <option value={84}>Gibraltar</option>
                    <option value={85}>Greece</option>
                    <option value={86}>Greenland</option>
                    <option value={87}>Grenada</option>
                    <option value={88}>Guadeloupe</option>
                    <option value={89}>Guam</option>
                    <option value={90}>Guatemala</option>
                    <option value={91}>Guernsey and Alderney</option>
                    <option value={92}>Guinea</option>
                    <option value={93}>Guinea-Bissau</option>
                    <option value={94}>Guyana</option>
                    <option value={95}>Haiti</option>
                    <option value={96}>Heard and McDonald Islands</option>
                    <option value={97}>Honduras</option>
                    <option value={98}>Hong Kong S.A.R.</option>
                    <option value={99}>Hungary</option>
                    <option value={100}>Iceland</option>
                    <option value={101} selected="selected">
                      India
                    </option>
                    <option value={102}>Indonesia</option>
                    <option value={103}>Iran</option>
                    <option value={104}>Iraq</option>
                    <option value={105}>Ireland</option>
                    <option value={106}>Israel</option>
                    <option value={107}>Italy</option>
                    <option value={108}>Jamaica</option>
                    <option value={109}>Japan</option>
                    <option value={110}>Jersey</option>
                    <option value={111}>Jordan</option>
                    <option value={112}>Kazakhstan</option>
                    <option value={113}>Kenya</option>
                    <option value={114}>Kiribati</option>
                    <option value={115}>Korea North</option>
                    <option value={116}>Korea South</option>
                    <option value={117}>Kuwait</option>
                    <option value={118}>Kyrgyzstan</option>
                    <option value={119}>Laos</option>
                    <option value={120}>Latvia</option>
                    <option value={121}>Lebanon</option>
                    <option value={122}>Lesotho</option>
                    <option value={123}>Liberia</option>
                    <option value={124}>Libya</option>
                    <option value={125}>Liechtenstein</option>
                    <option value={126}>Lithuania</option>
                    <option value={127}>Luxembourg</option>
                    <option value={128}>Macau S.A.R.</option>
                    <option value={129}>Macedonia</option>
                    <option value={130}>Madagascar</option>
                    <option value={131}>Malawi</option>
                    <option value={132}>Malaysia</option>
                    <option value={133}>Maldives</option>
                    <option value={134}>Mali</option>
                    <option value={135}>Malta</option>
                    <option value={136}>Man (Isle of)</option>
                    <option value={137}>Marshall Islands</option>
                    <option value={138}>Martinique</option>
                    <option value={139}>Mauritania</option>
                    <option value={140}>Mauritius</option>
                    <option value={141}>Mayotte</option>
                    <option value={142}>Mexico</option>
                    <option value={143}>Micronesia</option>
                    <option value={144}>Moldova</option>
                    <option value={145}>Monaco</option>
                    <option value={146}>Mongolia</option>
                    <option value={147}>Montserrat</option>
                    <option value={148}>Morocco</option>
                    <option value={149}>Mozambique</option>
                    <option value={150}>Myanmar</option>
                    <option value={151}>Namibia</option>
                    <option value={152}>Nauru</option>
                    <option value={153}>Nepal</option>
                    <option value={154}>Netherlands Antilles</option>
                    <option value={155}>Netherlands The</option>
                    <option value={156}>New Caledonia</option>
                    <option value={157}>New Zealand</option>
                    <option value={158}>Nicaragua</option>
                    <option value={159}>Niger</option>
                    <option value={160}>Nigeria</option>
                    <option value={161}>Niue</option>
                    <option value={162}>Norfolk Island</option>
                    <option value={163}>Northern Mariana Islands</option>
                    <option value={164}>Norway</option>
                    <option value={165}>Oman</option>
                    <option value={166}>Pakistan</option>
                    <option value={167}>Palau</option>
                    <option value={168}>Palestinian Territory Occupied</option>
                    <option value={169}>Panama</option>
                    <option value={170}>Papua new Guinea</option>
                    <option value={171}>Paraguay</option>
                    <option value={172}>Peru</option>
                    <option value={173}>Philippines</option>
                    <option value={174}>Pitcairn Island</option>
                    <option value={175}>Poland</option>
                    <option value={176}>Portugal</option>
                    <option value={177}>Puerto Rico</option>
                    <option value={178}>Qatar</option>
                    <option value={179}>Reunion</option>
                    <option value={180}>Romania</option>
                    <option value={181}>Russia</option>
                    <option value={182}>Rwanda</option>
                    <option value={183}>Saint Helena</option>
                    <option value={184}>Saint Kitts And Nevis</option>
                    <option value={185}>Saint Lucia</option>
                    <option value={186}>Saint Pierre and Miquelon</option>
                    <option value={187}>
                      Saint Vincent And The Grenadines
                    </option>
                    <option value={188}>Samoa</option>
                    <option value={189}>San Marino</option>
                    <option value={190}>Sao Tome and Principe</option>
                    <option value={191}>Saudi Arabia</option>
                    <option value={192}>Senegal</option>
                    <option value={193}>Serbia</option>
                    <option value={194}>Seychelles</option>
                    <option value={195}>Sierra Leone</option>
                    <option value={196}>Singapore</option>
                    <option value={197}>Slovakia</option>
                    <option value={198}>Slovenia</option>
                    <option value={199}>Smaller Territories of the UK</option>
                    <option value={200}>Solomon Islands</option>
                    <option value={201}>Somalia</option>
                    <option value={202}>South Africa</option>
                    <option value={203}>South Georgia</option>
                    <option value={204}>South Sudan</option>
                    <option value={205}>Spain</option>
                    <option value={206}>Sri Lanka</option>
                    <option value={207}>Sudan</option>
                    <option value={208}>Suriname</option>
                    <option value={209}>Svalbard And Jan Mayen Islands</option>
                    <option value={210}>Swaziland</option>
                    <option value={211}>Sweden</option>
                    <option value={212}>Switzerland</option>
                    <option value={213}>Syria</option>
                    <option value={214}>Taiwan</option>
                    <option value={215}>Tajikistan</option>
                    <option value={216}>Tanzania</option>
                    <option value={217}>Thailand</option>
                    <option value={218}>Togo</option>
                    <option value={219}>Tokelau</option>
                    <option value={220}>Tonga</option>
                    <option value={221}>Trinidad And Tobago</option>
                    <option value={222}>Tunisia</option>
                    <option value={223}>Turkey</option>
                    <option value={224}>Turkmenistan</option>
                    <option value={225}>Turks And Caicos Islands</option>
                    <option value={226}>Tuvalu</option>
                    <option value={227}>Uganda</option>
                    <option value={228}>Ukraine</option>
                    <option value={229}>United Arab Emirates</option>
                    <option value={230}>United Kingdom</option>
                    <option value={231}>United States of America</option>
                    <option value={232}>
                      United States Minor Outlying Islands
                    </option>
                    <option value={233}>Uruguay</option>
                    <option value={234}>Uzbekistan</option>
                    <option value={235}>Vanuatu</option>
                    <option value={236}>Vatican City State (Holy See)</option>
                    <option value={237}>Venezuela</option>
                    <option value={238}>Vietnam</option>
                    <option value={239}>Virgin Islands (British)</option>
                    <option value={240}>Virgin Islands (US)</option>
                    <option value={241}>Wallis And Futuna Islands</option>
                    <option value={242}>Western Sahara</option>
                    <option value={243}>Yemen</option>
                    <option value={244}>Yugoslavia</option>
                    <option value={245}>Zambia</option>
                    <option value={246}>Zimbabwe</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>State</label>
                  <span id="state_dd">
                    <select
                      id="state_id"
                      className="form-control"
                      name="state_id"
                    >
                      <option value>Select State</option>
                      <option value={1}>Andaman and Nicobar Islands</option>
                      <option value={2}>Andhra Pradesh</option>
                      <option value={3}>Arunachal Pradesh</option>
                      <option value={4}>Assam</option>
                      <option value={5}>Bihar</option>
                      <option value={6}>Chandigarh</option>
                      <option value={7}>Chhattisgarh</option>
                      <option value={8}>Dadra and Nagar Haveli</option>
                      <option value={9}>Daman and Diu</option>
                      <option value={10}>Delhi</option>
                      <option value={11}>Goa</option>
                      <option value={12}>Gujarat</option>
                      <option value={13}>Haryana</option>
                      <option value={14}>Himachal Pradesh</option>
                      <option value={15}>Jammu and Kashmir</option>
                      <option value={16}>Jharkhand</option>
                      <option value={17}>Karnataka</option>
                      <option value={18}>Kenmore</option>
                      <option value={19}>Kerala</option>
                      <option value={20}>Lakshadweep</option>
                      <option value={21}>Madhya Pradesh</option>
                      <option value={22}>Maharashtra</option>
                      <option value={23}>Manipur</option>
                      <option value={24}>Meghalaya</option>
                      <option value={25}>Mizoram</option>
                      <option value={26}>Nagaland</option>
                      <option value={27}>Narora</option>
                      <option value={28}>Natwar</option>
                      <option value={29}>Odisha</option>
                      <option value={30}>Paschim Medinipur</option>
                      <option value={31}>Pondicherry</option>
                      <option value={32}>Punjab</option>
                      <option value={33}>Rajasthan</option>
                      <option value={34}>Sikkim</option>
                      <option value={35}>Tamil Nadu</option>
                      <option value={36}>Telangana</option>
                      <option value={37}>Tripura</option>
                      <option value={38} selected="selected">
                        Uttar Pradesh
                      </option>
                      <option value={39}>Uttarakhand</option>
                      <option value={40}>Vaishali</option>
                      <option value={41}>West Bengal</option>
                    </select>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>City</label>
                  <span id="city_dd">
                    <select
                      id="city_id"
                      className="form-control"
                      name="city_id"
                    >
                      <option value>Select City</option>
                      <option value={4531}>Achhalda</option>
                      <option value={4532}>Achhnera</option>
                      <option value={4533}>Adari</option>
                      <option value={4534}>Afzalgarh</option>
                      <option value={4535}>Agarwal Mandi</option>
                      <option value={4536}>Agra</option>
                      <option value={4537}>Agra Cantonment</option>
                      <option value={4538}>Ahraura</option>
                      <option value={4539}>Ailum</option>
                      <option value={4540}>Air Force Area</option>
                      <option value={4541}>Ajhuwa</option>
                      <option value={4542}>Akbarpur</option>
                      <option value={4543}>Alapur</option>
                      <option value={4544}>Aliganj</option>
                      <option value={4545}>Aligarh</option>
                      <option value={4546}>Allahabad</option>
                      <option value={4547}>Allahabad Cantonment</option>
                      <option value={4548}>Allahganj</option>
                      <option value={4549}>Amanpur</option>
                      <option value={4550}>Ambahta</option>
                      <option value={4551}>Amethi</option>
                      <option value={4552}>Amila</option>
                      <option value={4553}>Amilo</option>
                      <option value={4554}>Aminagar Sarai</option>
                      <option value={4555}>Aminagar Urf Bhurbaral</option>
                      <option value={4556}>Amraudha</option>
                      <option value={4557}>Amroha</option>
                      <option value={4558}>Anandnagar</option>
                      <option value={4559}>Anpara</option>
                      <option value={4560}>Antu</option>
                      <option value={4561}>Anupshahr</option>
                      <option value={4562}>Aonla</option>
                      <option value={4563}>Armapur Estate</option>
                      <option value={4564}>Ashokpuram</option>
                      <option value={4565}>Ashrafpur Kichhauchha</option>
                      <option value={4566}>Atarra</option>
                      <option value={4567}>Atasu</option>
                      <option value={4568}>Atrauli</option>
                      <option value={4569}>Atraulia</option>
                      <option value={4570}>Auraiya</option>
                      <option value={4571}>Aurangabad</option>
                      <option value={4572}>Aurangabad Bangar</option>
                      <option value={4573}>Auras</option>
                      <option value={4574}>Awagarh</option>
                      <option value={4575}>Ayodhya</option>
                      <option value={4576}>Azamgarh</option>
                      <option value={4577}>Azizpur</option>
                      <option value={4578}>Azmatgarh</option>
                      <option value={4579}>Babarpur Ajitmal</option>
                      <option value={4580}>Baberu</option>
                      <option value={4581}>Babina</option>
                      <option value={4582}>Babrala</option>
                      <option value={4583}>Babugarh</option>
                      <option value={4584}>Bachhiowan</option>
                      <option value={4585}>Bachhraon</option>
                      <option value={4586}>Bad</option>
                      <option value={4587}>Badaun</option>
                      <option value={4588}>Baghpat</option>
                      <option value={4589}>Bah</option>
                      <option value={4590}>Bahadurganj</option>
                      <option value={4591}>Baheri</option>
                      <option value={4592}>Bahjoi</option>
                      <option value={4593}>Bahraich</option>
                      <option value={4594}>Bahsuma</option>
                      <option value={4595}>Bahua</option>
                      <option value={4596}>Bajna</option>
                      <option value={4597}>Bakewar</option>
                      <option value={4598}>Bakiabad</option>
                      <option value={4599}>Baldeo</option>
                      <option value={4600}>Ballia</option>
                      <option value={4601}>Balrampur</option>
                      <option value={4602}>Banat</option>
                      <option value={4603}>Banda</option>
                      <option value={4604}>Bangarmau</option>
                      <option value={4605}>Banki</option>
                      <option value={4606}>Bansdih</option>
                      <option value={4607}>Bansgaon</option>
                      <option value={4608}>Bansi</option>
                      <option value={4609}>Barabanki</option>
                      <option value={4610}>Baragaon</option>
                      <option value={4611}>Baraut</option>
                      <option value={4612}>Bareilly</option>
                      <option value={4613}>Bareilly Cantonment</option>
                      <option value={4614}>Barhalganj</option>
                      <option value={4615}>Barhani</option>
                      <option value={4616}>Barhapur</option>
                      <option value={4617}>Barkhera</option>
                      <option value={4618}>Barsana</option>
                      <option value={4619}>Barva Sagar</option>
                      <option value={4620}>Barwar</option>
                      <option value={4621}>Basti</option>
                      <option value={4622}>Begumabad Budhana</option>
                      <option value={4623}>Behat</option>
                      <option value={4624}>Behta Hajipur</option>
                      <option value={4625}>Bela</option>
                      <option value={4626}>Belthara</option>
                      <option value={4627}>Beniganj</option>
                      <option value={4628}>Beswan</option>
                      <option value={4629}>Bewar</option>
                      <option value={4630}>Bhadarsa</option>
                      <option value={4631}>Bhadohi</option>
                      <option value={4632}>Bhagwantnagar</option>
                      <option value={4633}>Bharatganj</option>
                      <option value={4634}>Bhargain</option>
                      <option value={4635}>Bharthana</option>
                      <option value={4636}>Bharuhana</option>
                      <option value={4637}>Bharwari</option>
                      <option value={4638}>Bhatni Bazar</option>
                      <option value={4639}>Bhatpar Rani</option>
                      <option value={4640}>Bhawan Bahadurnagar</option>
                      <option value={4641}>Bhinga</option>
                      <option value={4642}>Bhojpur Dharampur</option>
                      <option value={4643}>Bhokarhedi</option>
                      <option value={4644}>Bhongaon</option>
                      <option value={4645}>Bhulepur</option>
                      <option value={4646}>Bidhuna</option>
                      <option value={4647}>Bighapur</option>
                      <option value={4648}>Bijnor</option>
                      <option value={4649}>Bijpur</option>
                      <option value={4650}>Bikapur</option>
                      <option value={4651}>Bilari</option>
                      <option value={4652}>Bilaspur</option>
                      <option value={4653}>Bilgram</option>
                      <option value={4654}>Bilhaur</option>
                      <option value={4655}>Bilram</option>
                      <option value={4656}>Bilrayaganj</option>
                      <option value={4657}>Bilsanda</option>
                      <option value={4658}>Bilsi</option>
                      <option value={4659}>Bindki</option>
                      <option value={4660}>Bisalpur</option>
                      <option value={4661}>Bisanda Buzurg</option>
                      <option value={4662}>Bisauli</option>
                      <option value={4663}>Bisharatganj</option>
                      <option value={4664}>Bisokhar</option>
                      <option value={4665}>Biswan</option>
                      <option value={4666}>Bithur</option>
                      <option value={4667}>Budaun</option>
                      <option value={4668}>Bugrasi</option>
                      <option value={4669}>Bulandshahar</option>
                      <option value={4670}>Burhana</option>
                      <option value={4671}>Chail</option>
                      <option value={4672}>Chak Imam Ali</option>
                      <option value={4673}>Chakeri</option>
                      <option value={4674}>Chakia</option>
                      <option value={4675}>Chandauli</option>
                      <option value={4676}>Chandausi</option>
                      <option value={4677}>Chandpur</option>
                      <option value={4678}>Charkhari</option>
                      <option value={4679}>Charthawal</option>
                      <option value={4680}>Chaumuhan</option>
                      <option value={4681}>Chhaprauli</option>
                      <option value={4682}>Chhara Rafatpur</option>
                      <option value={4683}>Chharprauli</option>
                      <option value={4684}>Chhata</option>
                      <option value={4685}>Chhatari</option>
                      <option value={4686}>Chhibramau</option>
                      <option value={4687}>Chhutmalpur</option>
                      <option value={4688}>Chilkana Sultanpur</option>
                      <option value={4689}>Chirgaon</option>
                      <option value={4690}>Chit Baragaon</option>
                      <option value={4691}>Chitrakut Dham</option>
                      <option value={4692}>Chopan</option>
                      <option value={4693}>Choubepur Kalan</option>
                      <option value={4694}>Chunar</option>
                      <option value={4695}>Churk Ghurma</option>
                      <option value={4696}>Colonelganj</option>
                      <option value={4697}>Dadri</option>
                      <option value={4698}>Dalmau</option>
                      <option value={4699}>Dankaur</option>
                      <option value={4700}>Dariyabad</option>
                      <option value={4701}>Dasna</option>
                      <option value={4702}>Dataganj</option>
                      <option value={4703}>Daurala</option>
                      <option value={4704}>Dayal Bagh</option>
                      <option value={4705}>Deoband</option>
                      <option value={4706}>Deoranian</option>
                      <option value={4707}>Deoria</option>
                      <option value={4708}>Dewa</option>
                      <option value={4709}>Dhampur</option>
                      <option value={4710}>Dhanauha</option>
                      <option value={4711}>Dhanauli</option>
                      <option value={4712}>Dhanaura</option>
                      <option value={4713}>Dharoti Khurd</option>
                      <option value={4714}>Dhauratanda</option>
                      <option value={4715}>Dhaurhra</option>
                      <option value={4716}>Dibai</option>
                      <option value={4717}>Dibiyapur</option>
                      <option value={4718}>Dildarnagar Fatehpur</option>
                      <option value={4719}>Do Ghat</option>
                      <option value={4720}>Dohrighat</option>
                      <option value={4721}>Dostpur</option>
                      <option value={4722}>Dudhinagar</option>
                      <option value={4723}>Dulhipur</option>
                      <option value={4724}>Dundwaraganj</option>
                      <option value={4725}>Ekdil</option>
                      <option value={4726}>Erich</option>
                      <option value={4727}>Etah</option>
                      <option value={4728}>Etawah</option>
                      <option value={4729}>Faizabad</option>
                      <option value={4730}>Faizabad Cantonment</option>
                      <option value={4731}>Faizganj</option>
                      <option value={4732}>Farah</option>
                      <option value={4733}>Faridnagar</option>
                      <option value={4734}>Faridpur</option>
                      <option value={4735}>Faridpur Cantonment</option>
                      <option value={4736}>Fariha</option>
                      <option value={4737}>Farrukhabad</option>
                      <option value={4738}>Fatehabad</option>
                      <option value={4739}>Fatehganj Pashchimi</option>
                      <option value={4740}>Fatehganj Purvi</option>
                      <option value={4741}>Fatehgarh</option>
                      <option value={4742}>Fatehpur</option>
                      <option value={4743}>Fatehpur Chaurasi</option>
                      <option value={4744}>Fatehpur Sikri</option>
                      <option value={4745}>Firozabad</option>
                      <option value={4746}>Gajraula</option>
                      <option value={4747}>Ganga Ghat</option>
                      <option value={4748}>Gangapur</option>
                      <option value={4749}>Gangoh</option>
                      <option value={4750}>Ganj Muradabad</option>
                      <option value={4751}>Garautha</option>
                      <option value={4752}>Garhi Pukhta</option>
                      <option value={4753}>Garhmukteshwar</option>
                      <option value={4754}>Gaura Barahaj</option>
                      <option value={4755}>Gauri Bazar</option>
                      <option value={4756}>Gausganj</option>
                      <option value={4757}>Gawan</option>
                      <option value={4758}>Ghatampur</option>
                      <option value={4759}>Ghaziabad</option>
                      <option value={4760}>Ghazipur</option>
                      <option value={4761}>Ghiror</option>
                      <option value={4762}>Ghorawal</option>
                      <option value={4763}>Ghosi</option>
                      <option value={4764}>Ghosia Bazar</option>
                      <option value={4765}>Ghughuli</option>
                      <option value={4766}>Gohand</option>
                      <option value={4767}>Gokul</option>
                      <option value={4768}>Gola Bazar</option>
                      <option value={4769}>Gola Gokarannath</option>
                      <option value={4770}>Gonda</option>
                      <option value={4771}>Gopamau</option>
                      <option value={4772}>Gopiganj</option>
                      <option value={4773}>Gorakhpur</option>
                      <option value={4774}>Gosainganj</option>
                      <option value={4775}>Govardhan</option>
                      <option value={4776}>Greater Noida</option>
                      <option value={4777}>Gulaothi</option>
                      <option value={4778}>Gulariya</option>
                      <option value={4779}>Gulariya Bhindara</option>
                      <option value={4780}>Gunnaur</option>
                      <option value={4781}>Gursahaiganj</option>
                      <option value={4782}>Gursarai</option>
                      <option value={4783}>Gyanpur</option>
                      <option value={4784}>Hafizpur</option>
                      <option value={4785}>Haidergarh</option>
                      <option value={4786}>Haldaur</option>
                      <option value={4787}>Hamirpur</option>
                      <option value={4788}>Handia</option>
                      <option value={4789}>Hapur</option>
                      <option value={4790}>Hardoi</option>
                      <option value={4791}>Harduaganj</option>
                      <option value={4792}>Hargaon</option>
                      <option value={4793}>Hariharpur</option>
                      <option value={4794}>Harraiya</option>
                      <option value={4795}>Hasanpur</option>
                      <option value={4796}>Hasayan</option>
                      <option value={4797}>Hastinapur</option>
                      <option value={4798}>Hata</option>
                      <option value={4799}>Hathras</option>
                      <option value={4800}>Hyderabad</option>
                      <option value={4801}>Ibrahimpur</option>
                      <option value={4802}>Iglas</option>
                      <option value={4803}>Ikauna</option>
                      <option value={4804}>Iltifatganj Bazar</option>
                      <option value={4805}>
                        Indian Telephone Industry Mank
                      </option>
                      <option value={4806}>Islamnagar</option>
                      <option value={4807}>Itaunja</option>
                      <option value={4808}>Itimadpur</option>
                      <option value={4809}>Jagner</option>
                      <option value={4810}>Jahanabad</option>
                      <option value={4811}>Jahangirabad</option>
                      <option value={4812}>Jahangirpur</option>
                      <option value={4813}>Jais</option>
                      <option value={4814}>Jaithara</option>
                      <option value={4815}>Jalalabad</option>
                      <option value={4816}>Jalali</option>
                      <option value={4817}>Jalalpur</option>
                      <option value={4818}>Jalaun</option>
                      <option value={4819}>Jalesar</option>
                      <option value={4820}>Jamshila</option>
                      <option value={4821}>Jangipur</option>
                      <option value={4822}>Jansath</option>
                      <option value={4823}>Jarwal</option>
                      <option value={4824}>Jasrana</option>
                      <option value={4825}>Jaswantnagar</option>
                      <option value={4826}>Jatari</option>
                      <option value={4827}>Jaunpur</option>
                      <option value={4828}>Jewar</option>
                      <option value={4829}>Jhalu</option>
                      <option value={4830}>Jhansi</option>
                      <option value={4831}>Jhansi Cantonment</option>
                      <option value={4832}>Jhansi Railway Settlement</option>
                      <option value={4833}>Jhinjhak</option>
                      <option value={4834}>Jhinjhana</option>
                      <option value={4835}>Jhusi</option>
                      <option value={4836}>Jhusi Kohna</option>
                      <option value={4837}>Jiyanpur</option>
                      <option value={4838}>Joya</option>
                      <option value={4839}>Jyoti Khuria</option>
                      <option value={4840}>Jyotiba Phule Nagar</option>
                      <option value={4841}>Kabrai</option>
                      <option value={4842}>Kachhauna Patseni</option>
                      <option value={4843}>Kachhla</option>
                      <option value={4844}>Kachhwa</option>
                      <option value={4845}>Kadaura</option>
                      <option value={4846}>Kadipur</option>
                      <option value={4847}>Kailashpur</option>
                      <option value={4848}>Kaimganj</option>
                      <option value={4849}>Kairana</option>
                      <option value={4850}>Kakgaina</option>
                      <option value={4851}>Kakod</option>
                      <option value={4852}>Kakori</option>
                      <option value={4853}>Kakrala</option>
                      <option value={4854}>Kalinagar</option>
                      <option value={4855}>Kalpi</option>
                      <option value={4856}>Kamalganj</option>
                      <option value={4857}>Kampil</option>
                      <option value={4858}>Kandhla</option>
                      <option value={4859}>Kandwa</option>
                      <option value={4860}>Kannauj</option>
                      <option value={4861}>Kanpur</option>
                      <option value={4862}>Kant</option>
                      <option value={4863}>Kanth</option>
                      <option value={4864}>Kaptanganj</option>
                      <option value={4865}>Karaon</option>
                      <option value={4866}>Karari</option>
                      <option value={4867}>Karhal</option>
                      <option value={4868}>Karnawal</option>
                      <option value={4869}>Kasganj</option>
                      <option value={4870}>Katariya</option>
                      <option value={4871}>Katghar Lalganj</option>
                      <option value={4872}>Kathera</option>
                      <option value={4873}>Katra</option>
                      <option value={4874}>Katra Medniganj</option>
                      <option value={4875}>Kauriaganj</option>
                      <option value={4876}>Kemri</option>
                      <option value={4877}>Kerakat</option>
                      <option value={4878}>Khadda</option>
                      <option value={4879}>Khaga</option>
                      <option value={4880}>Khailar</option>
                      <option value={4881}>Khair</option>
                      <option value={4882}>Khairabad</option>
                      <option value={4883}>Khairagarh</option>
                      <option value={4884}>Khalilabad</option>
                      <option value={4885}>Khamaria</option>
                      <option value={4886}>Khanpur</option>
                      <option value={4887}>Kharela</option>
                      <option value={4888}>Khargupur</option>
                      <option value={4889}>Khariya</option>
                      <option value={4890}>Kharkhoda</option>
                      <option value={4891}>Khatauli</option>
                      <option value={4892}>Khatauli Rural</option>
                      <option value={4893}>Khekra</option>
                      <option value={4894}>Kheri</option>
                      <option value={4895}>Kheta Sarai</option>
                      <option value={4896}>Khudaganj</option>
                      <option value={4897}>Khurja</option>
                      <option value={4898}>Khutar</option>
                      <option value={4899}>Kiraoli</option>
                      <option value={4900}>Kiratpur</option>
                      <option value={4901}>Kishanpur</option>
                      <option value={4902}>Kishni</option>
                      <option value={4903}>Kithaur</option>
                      <option value={4904}>Koiripur</option>
                      <option value={4905}>Konch</option>
                      <option value={4906}>Kopaganj</option>
                      <option value={4907}>Kora Jahanabad</option>
                      <option value={4908}>Korwa</option>
                      <option value={4909}>Kosi Kalan</option>
                      <option value={4910}>Kota</option>
                      <option value={4911}>Kotra</option>
                      <option value={4912}>Kotwa</option>
                      <option value={4913}>Kulpahar</option>
                      <option value={4914}>Kunda</option>
                      <option value={4915}>Kundarki</option>
                      <option value={4916}>Kunwargaon</option>
                      <option value={4917}>Kurara</option>
                      <option value={4918}>Kurawali</option>
                      <option value={4919}>Kursath</option>
                      <option value={4920}>Kurthi Jafarpur</option>
                      <option value={4921}>Kushinagar</option>
                      <option value={4922}>Kusmara</option>
                      <option value={4923}>Laharpur</option>
                      <option value={4924}>Lakhimpur</option>
                      <option value={4925}>Lakhna</option>
                      <option value={4926}>Lalganj</option>
                      <option value={4927}>Lalitpur</option>
                      <option value={4928}>Lar</option>
                      <option value={4929}>Lawar</option>
                      <option value={4930}>Ledwa Mahuwa</option>
                      <option value={4931}>Lohta</option>
                      <option value={4932}>Loni</option>
                      <option value={4933}>Lucknow</option>
                      <option value={4934}>Machhlishahr</option>
                      <option value={4935}>Madhoganj</option>
                      <option value={4936}>Madhogarh</option>
                      <option value={4937}>Maghar</option>
                      <option value={4938}>Mahaban</option>
                      <option value={4939}>Maharajganj</option>
                      <option value={4940}>Mahmudabad</option>
                      <option value={4941}>Mahoba</option>
                      <option value={4942}>Maholi</option>
                      <option value={4943}>Mahona</option>
                      <option value={4944}>Mahroni</option>
                      <option value={4945}>Mailani</option>
                      <option value={4946}>Mainpuri</option>
                      <option value={4947}>Majhara Pipar Ehatmali</option>
                      <option value={4948}>Majhauli Raj</option>
                      <option value={4949}>Malihabad</option>
                      <option value={4950}>Mallanwam</option>
                      <option value={4951}>Mandawar</option>
                      <option value={4952}>Manikpur</option>
                      <option value={4953}>Maniyar</option>
                      <option value={4954}>Manjhanpur</option>
                      <option value={4955}>Mankapur</option>
                      <option value={4956}>Marehra</option>
                      <option value={4957}>Mariahu</option>
                      <option value={4958}>Maruadih</option>
                      <option value={4959}>Maswasi</option>
                      <option value={4960}>Mataundh</option>
                      <option value={4961}>Mathu</option>
                      <option value={4962}>Mathura</option>
                      <option value={4963}>Mathura Cantonment</option>
                      <option value={4964}>Mau</option>
                      <option value={4965}>Mau Aima</option>
                      <option value={4966}>Maudaha</option>
                      <option value={4967}>Mauranipur</option>
                      <option value={4968}>Maurawan</option>
                      <option value={4969}>Mawana</option>
                      <option value={4970}>Meerut</option>
                      <option value={4971}>Mehnagar</option>
                      <option value={4972}>Mehndawal</option>
                      <option value={4973}>Mendu</option>
                      <option value={4974}>Milak</option>
                      <option value={4975}>Miranpur</option>
                      <option value={4976}>Mirat</option>
                      <option value={4977}>Mirat Cantonment</option>
                      <option value={4978}>Mirganj</option>
                      <option value={4979}>Mirzapur</option>
                      <option value={4980}>Misrikh</option>
                      <option value={4981}>Modinagar</option>
                      <option value={4982}>Mogra Badshahpur</option>
                      <option value={4983}>Mohan</option>
                      <option value={4984}>Mohanpur</option>
                      <option value={4985}>Mohiuddinpur</option>
                      <option value={4986}>Moradabad</option>
                      <option value={4987}>Moth</option>
                      <option value={4988}>Mubarakpur</option>
                      <option value={4989}>Mughal Sarai</option>
                      <option value={4990}>
                        Mughal Sarai Railway Settlemen
                      </option>
                      <option value={4991}>Muhammadabad</option>
                      <option value={4992}>Muhammadi</option>
                      <option value={4993}>Mukrampur Khema</option>
                      <option value={4994}>Mundia</option>
                      <option value={4995}>Mundora</option>
                      <option value={4996}>Muradnagar</option>
                      <option value={4997}>Mursan</option>
                      <option value={4998}>Musafirkhana</option>
                      <option value={4999}>Muzaffarnagar</option>
                      <option value={5000}>Nadigaon</option>
                      <option value={5001}>Nagina</option>
                      <option value={5002}>Nagram</option>
                      <option value={5003}>Nai Bazar</option>
                      <option value={5004}>Nainana Jat</option>
                      <option value={5005}>Najibabad</option>
                      <option value={5006}>Nakur</option>
                      <option value={5007}>Nanaunta</option>
                      <option value={5008}>Nandgaon</option>
                      <option value={5009}>Nanpara</option>
                      <option value={5010}>Naraini</option>
                      <option value={5011}>Narauli</option>
                      <option value={5012}>Naraura</option>
                      <option value={5013}>Naugawan Sadat</option>
                      <option value={5014}>Nautanwa</option>
                      <option value={5015}>Nawabganj</option>
                      <option value={5016}>Nichlaul</option>
                      <option value={5017}>Nidhauli Kalan</option>
                      <option value={5018}>Nihtaur</option>
                      <option value={5019}>Nindaura</option>
                      <option value={5020}>Niwari</option>
                      <option value={5021}>Nizamabad</option>
                      <option value={5022}>Noida</option>
                      <option value={5023}>Northern Railway Colony</option>
                      <option value={5024}>Nurpur</option>
                      <option value={5025}>Nyoria Husenpur</option>
                      <option value={5026}>Nyotini</option>
                      <option value={5027}>Obra</option>
                      <option value={5028}>Oel Dhakwa</option>
                      <option value={5029}>Orai</option>
                      <option value={5030}>Oran</option>
                      <option value={5031}>Ordinance Factory Muradnagar</option>
                      <option value={5032}>Pachperwa</option>
                      <option value={5033}>Padrauna</option>
                      <option value={5034}>Pahasu</option>
                      <option value={5035}>Paintepur</option>
                      <option value={5036}>Pali</option>
                      <option value={5037}>Palia Kalan</option>
                      <option value={5038}>Parasi</option>
                      <option value={5039}>Parichha</option>
                      <option value={5040}>Parichhatgarh</option>
                      <option value={5041}>Parsadepur</option>
                      <option value={5042}>Patala</option>
                      <option value={5043}>Patiyali</option>
                      <option value={5044}>Patti</option>
                      <option value={5045}>Pawayan</option>
                      <option value={5046}>Phalauda</option>
                      <option value={5047}>Phaphund</option>
                      <option value={5048}>Phulpur</option>
                      <option value={5049}>Phulwaria</option>
                      <option value={5050}>Pihani</option>
                      <option value={5051}>Pilibhit</option>
                      <option value={5052}>Pilkana</option>
                      <option value={5053}>Pilkhuwa</option>
                      <option value={5054}>Pinahat</option>
                      <option value={5055}>Pipalsana Chaudhari</option>
                      <option value={5056}>Pipiganj</option>
                      <option value={5057}>Pipraich</option>
                      <option value={5058}>Pipri</option>
                      <option value={5059}>Pratapgarh</option>
                      <option value={5060}>Pukhrayan</option>
                      <option value={5061}>Puranpur</option>
                      <option value={5062}>Purdil Nagar</option>
                      <option value={5063}>Purqazi</option>
                      <option value={5064}>Purwa</option>
                      <option value={5065}>Qasimpur</option>
                      <option value={5066}>Rabupura</option>
                      <option value={5067}>Radha Kund</option>
                      <option value={5068}>Rae Bareilly</option>
                      <option value={5069}>Raja Ka Rampur</option>
                      <option value={5070}>Rajapur</option>
                      <option value={5071}>Ramkola</option>
                      <option value={5072}>Ramnagar</option>
                      <option value={5073}>Rampur</option>
                      <option value={5074}>Rampur Bhawanipur</option>
                      <option value={5075}>Rampur Karkhana</option>
                      <option value={5076}>Rampur Maniharan</option>
                      <option value={5077}>Rampura</option>
                      <option value={5078}>Ranipur</option>
                      <option value={5079}>Rashidpur Garhi</option>
                      <option value={5080}>Rasra</option>
                      <option value={5081}>Rasulabad</option>
                      <option value={5082}>Rath</option>
                      <option value={5083}>Raya</option>
                      <option value={5084}>Renukut</option>
                      <option value={5085}>Reoti</option>
                      <option value={5086}>Richha</option>
                      <option value={5087}>Risia Bazar</option>
                      <option value={5088}>Rithora</option>
                      <option value={5089}>Robertsganj</option>
                      <option value={5090}>Roza</option>
                      <option value={5091}>Rudarpur</option>
                      <option value={5092}>Rudauli</option>
                      <option value={5093}>Rudayan</option>
                      <option value={5094}>Rura</option>
                      <option value={5095}>Rustamnagar Sahaspur</option>
                      <option value={5096}>Sabatwar</option>
                      <option value={5097}>Sadabad</option>
                      <option value={5098}>Sadat</option>
                      <option value={5099}>Safipur</option>
                      <option value={5100}>Sahanpur</option>
                      <option value={5101} selected="selected">
                        Saharanpur
                      </option>
                      <option value={5102}>Sahaspur</option>
                      <option value={5103}>Sahaswan</option>
                      <option value={5104}>Sahawar</option>
                      <option value={5105}>Sahibabad</option>
                      <option value={5106}>Sahjanwa</option>
                      <option value={5107}>Sahpau</option>
                      <option value={5108}>Saidpur</option>
                      <option value={5109}>Sainthal</option>
                      <option value={5110}>Saiyadraja</option>
                      <option value={5111}>Sakhanu</option>
                      <option value={5112}>Sakit</option>
                      <option value={5113}>Salarpur Khadar</option>
                      <option value={5114}>Salimpur</option>
                      <option value={5115}>Salon</option>
                      <option value={5116}>Sambhal</option>
                      <option value={5117}>Sambhawali</option>
                      <option value={5118}>Samdhan</option>
                      <option value={5119}>Samthar</option>
                      <option value={5120}>Sandi</option>
                      <option value={5121}>Sandila</option>
                      <option value={5123}>Sarai akil</option>
                      <option value={5122}>Sarai Mir</option>
                      <option value={5124}>Sarauli</option>
                      <option value={5125}>Sardhana</option>
                      <option value={5126}>Sarila</option>
                      <option value={5127}>Sarsawan</option>
                      <option value={5128}>Sasni</option>
                      <option value={5129}>Satrikh</option>
                      <option value={5130}>Saunkh</option>
                      <option value={5131}>Saurikh</option>
                      <option value={5132}>Seohara</option>
                      <option value={5133}>Sewal Khas</option>
                      <option value={5134}>Sewarhi</option>
                      <option value={5135}>Shahabad</option>
                      <option value={5136}>Shahganj</option>
                      <option value={5137}>Shahi</option>
                      <option value={5138}>Shahjahanpur</option>
                      <option value={5139}>Shahjahanpur Cantonment</option>
                      <option value={5140}>Shahpur</option>
                      <option value={5141}>Shamli</option>
                      <option value={5142}>Shamsabad</option>
                      <option value={5143}>Shankargarh</option>
                      <option value={5144}>Shergarh</option>
                      <option value={5145}>Sherkot</option>
                      <option value={5146}>Shikarpur</option>
                      <option value={5147}>Shikohabad</option>
                      <option value={5148}>Shisgarh</option>
                      <option value={5149}>Shivdaspur</option>
                      <option value={5150}>Shivli</option>
                      <option value={5151}>Shivrajpur</option>
                      <option value={5152}>Shohratgarh</option>
                      <option value={5153}>Siddhanur</option>
                      <option value={5154}>Siddharthnagar</option>
                      <option value={5155}>Sidhauli</option>
                      <option value={5156}>Sidhpura</option>
                      <option value={5157}>Sikandarabad</option>
                      <option value={5158}>Sikandarpur</option>
                      <option value={5159}>Sikandra</option>
                      <option value={5160}>Sikandra Rao</option>
                      <option value={5161}>Singahi Bhiraura</option>
                      <option value={5162}>Sirathu</option>
                      <option value={5163}>Sirsa</option>
                      <option value={5164}>Sirsaganj</option>
                      <option value={5165}>Sirsi</option>
                      <option value={5166}>Sisauli</option>
                      <option value={5167}>Siswa Bazar</option>
                      <option value={5168}>Sitapur</option>
                      <option value={5169}>Siyana</option>
                      <option value={5170}>Som</option>
                      <option value={5171}>Sonbhadra</option>
                      <option value={5172}>Soron</option>
                      <option value={5173}>Suar</option>
                      <option value={5174}>Sukhmalpur Nizamabad</option>
                      <option value={5175}>Sultanpur</option>
                      <option value={5176}>Sumerpur</option>
                      <option value={5177}>Suriyawan</option>
                      <option value={5178}>Swamibagh</option>
                      <option value={5179}>Tajpur</option>
                      <option value={5180}>Talbahat</option>
                      <option value={5181}>Talgram</option>
                      <option value={5182}>Tambaur</option>
                      <option value={5183}>Tanda</option>
                      <option value={5184}>Tatarpur Lallu</option>
                      <option value={5185}>Tetribazar</option>
                      <option value={5186}>Thakurdwara</option>
                      <option value={5187}>Thana Bhawan</option>
                      <option value={5188}>Thiriya Nizamat Khan</option>
                      <option value={5189}>Tikaitnagar</option>
                      <option value={5190}>Tikri</option>
                      <option value={5191}>Tilhar</option>
                      <option value={5192}>Tindwari</option>
                      <option value={5193}>Tirwaganj</option>
                      <option value={5194}>Titron</option>
                      <option value={5195}>Tori Fatehpur</option>
                      <option value={5196}>Tulsipur</option>
                      <option value={5197}>Tundla</option>
                      <option value={5198}>Tundla Kham</option>
                      <option value={5199}>Tundla Railway Colony</option>
                      <option value={5200}>Ugu</option>
                      <option value={5201}>Ujhani</option>
                      <option value={5202}>Ujhari</option>
                      <option value={5203}>Umri</option>
                      <option value={5204}>Umri Kalan</option>
                      <option value={5205}>Un</option>
                      <option value={5206}>Unchahar</option>
                      <option value={5207}>Unnao</option>
                      <option value={5208}>Usaihat</option>
                      <option value={5209}>Usawan</option>
                      <option value={5210}>Utraula</option>
                      <option value={5211}>Varanasi</option>
                      <option value={5212}>Varanasi Cantonment</option>
                      <option value={5213}>Vijaigarh</option>
                      <option value={5214}>Vrindavan</option>
                      <option value={5215}>Wazirganj</option>
                      <option value={5216}>Zafarabad</option>
                      <option value={5217}>Zaidpur</option>
                      <option value={5218}>Zamania</option>
                    </select>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Nationality</label>
                  <select
                    className="form-control"
                    id="nationality_id"
                    name="nationality_id"
                  >
                    <option value>Select Nationality</option>
                    <option value={1}>Afghans</option>
                    <option value={2}>Albanians</option>
                    <option value={3}>Algerians</option>
                    <option value={4}>Americans</option>
                    <option value={5}>Andorrans</option>
                    <option value={6}>Angolans</option>
                    <option value={7}>Anguilla</option>
                    <option value={8}>Antarctica</option>
                    <option value={9}>Antiguans and Barbudans</option>
                    <option value={10}>Argentines</option>
                    <option value={11}>Armenians</option>
                    <option value={12}>Arubans</option>
                    <option value={13}>Australians</option>
                    <option value={14}>Austrians</option>
                    <option value={15}>Azerbaijanis</option>
                    <option value={16}>Bahamians</option>
                    <option value={17}>Bahrainis</option>
                    <option value={18}>Bangladeshis</option>
                    <option value={19}>Barbadians</option>
                    <option value={20}>Belarusians</option>
                    <option value={21}>Belgians</option>
                    <option value={22}>Belizeans</option>
                    <option value={23}>Beninese</option>
                    <option value={24}>Bermudians</option>
                    <option value={25}>Bhutanese</option>
                    <option value={26}>Bolivians</option>
                    <option value={27}>Bosnians and Herzegovinians</option>
                    <option value={28}>Botswana</option>
                    <option value={29}>Bouvet Island</option>
                    <option value={30}>Brazilians</option>
                    <option value={31}>British Indian Ocean Territory</option>
                    <option value={32}>Bruneians</option>
                    <option value={33}>Bulgarians</option>
                    <option value={34}>Burkinabés</option>
                    <option value={35}>Burundians</option>
                    <option value={36}>Cambodians</option>
                    <option value={37}>Cameroonians</option>
                    <option value={38}>Canadians</option>
                    <option value={39}>Cape Verde</option>
                    <option value={40}>Cayman Islands</option>
                    <option value={41}>Central African Republic</option>
                    <option value={42}>Chadians</option>
                    <option value={43}>Chileans</option>
                    <option value={44}>Chinese</option>
                    <option value={45}>Christmas Island</option>
                    <option value={46}>Cocos (Keeling) Islands</option>
                    <option value={47}>Colombians</option>
                    <option value={48}>Comorians</option>
                    <option value={49}>Congolese</option>
                    <option value={50}>Congolese</option>
                    <option value={51}>Cook Islands</option>
                    <option value={52}>Costa Ricans</option>
                    <option value={53}>Cote D'Ivoire (Ivory Coast)</option>
                    <option value={54}>Croatians</option>
                    <option value={55}>Cubans</option>
                    <option value={56}>Cypriots</option>
                    <option value={57}>Czechs</option>
                    <option value={58}>Danes</option>
                    <option value={59}>Djiboutians</option>
                    <option value={60}>Dominicans (Commonwealth)</option>
                    <option value={61}>Dominicans (Republic)</option>
                    <option value={62}>East Timorese</option>
                    <option value={63}>Ecuadorians</option>
                    <option value={64}>Egyptians</option>
                    <option value={65}>El Salvadorian</option>
                    <option value={66}>Equatoguineans</option>
                    <option value={67}>Eritreans</option>
                    <option value={68}>Estonians</option>
                    <option value={69}>Ethiopians</option>
                    <option value={70}>
                      External Territories of Australia
                    </option>
                    <option value={71}>Falkland Islanders</option>
                    <option value={72}>Faroese</option>
                    <option value={73}>Fijians</option>
                    <option value={74}>Finns</option>
                    <option value={75}>French citizens</option>
                    <option value={76}>French citizens</option>
                    <option value={77}>French citizens</option>
                    <option value={78}>French citizens</option>
                    <option value={79}>Gabonese</option>
                    <option value={80}>Gambians</option>
                    <option value={81}>Georgians</option>
                    <option value={82}>Germans</option>
                    <option value={83}>Ghanaians</option>
                    <option value={84}>Gibraltarians</option>
                    <option value={85}>Greeks</option>
                    <option value={86}>Greenlander</option>
                    <option value={87}>Grenadians</option>
                    <option value={88}>Guadeloupe</option>
                    <option value={89}>Guam</option>
                    <option value={90}>Guatemalans</option>
                    <option value={91}>Guernsey and Alderney</option>
                    <option value={92}>Guineans</option>
                    <option value={93}>Guinea-Bissau nationals</option>
                    <option value={94}>Guyanese</option>
                    <option value={95}>Haitians</option>
                    <option value={96}>Heard and McDonald Islands</option>
                    <option value={97}>Hondurans</option>
                    <option value={98}>Hong Kongers</option>
                    <option value={99}>Hungarians</option>
                    <option value={100}>Icelanders</option>
                    <option value={101} selected="selected">
                      Indians
                    </option>
                    <option value={102}>Indonesians</option>
                    <option value={103}>Iranians</option>
                    <option value={104}>Iraqis</option>
                    <option value={105}>Irish</option>
                    <option value={106}>Israelis</option>
                    <option value={107}>Italians</option>
                    <option value={108}>Jamaicans</option>
                    <option value={109}>Japanese</option>
                    <option value={110}>Jersey</option>
                    <option value={111}>Jordanians</option>
                    <option value={112}>Kazakhs</option>
                    <option value={113}>Kenyans</option>
                    <option value={114}>Kiribati</option>
                    <option value={115}>Koreans</option>
                    <option value={116}>Koreans</option>
                    <option value={117}>Kuwaitis</option>
                    <option value={118}>Kyrgyzs</option>
                    <option value={119}>Lao</option>
                    <option value={120}>Latvians</option>
                    <option value={121}>Lebanese</option>
                    <option value={122}>Lesotho</option>
                    <option value={123}>Liberians</option>
                    <option value={124}>Libyans</option>
                    <option value={125}>Liechtensteiners</option>
                    <option value={126}>Lithuanians</option>
                    <option value={127}>Luxembourgers</option>
                    <option value={128}>Macao</option>
                    <option value={129}>Macedonians</option>
                    <option value={130}>Malagasy</option>
                    <option value={131}>Malawians</option>
                    <option value={132}>Malaysians</option>
                    <option value={133}>Maldivians</option>
                    <option value={134}>Malians</option>
                    <option value={135}>Maltese</option>
                    <option value={136}>Manx</option>
                    <option value={137}>Marshallese</option>
                    <option value={138}>Martinique</option>
                    <option value={139}>Mauritians</option>
                    <option value={140}>Mauritanians</option>
                    <option value={141}>Mayotte</option>
                    <option value={142}>Mexicans</option>
                    <option value={143}>Micronesians</option>
                    <option value={144}>Moldovans</option>
                    <option value={145}>Monégasque</option>
                    <option value={146}>Mongolians</option>
                    <option value={147}>Montenegrins</option>
                    <option value={148}>Moroccans</option>
                    <option value={149}>Mozambicans</option>
                    <option value={150}>Myanmar</option>
                    <option value={151}>Namibians</option>
                    <option value={152}>Naurans</option>
                    <option value={153}>Nepalese</option>
                    <option value={154}>Netherlands Antilles</option>
                    <option value={155}>Netherlands The</option>
                    <option value={156}>New Caledonia</option>
                    <option value={157}>New Zealanders</option>
                    <option value={158}>Nicaraguans</option>
                    <option value={159}>Nigeriens</option>
                    <option value={160}>Nigerians</option>
                    <option value={161}>Niue</option>
                    <option value={162}>Norfolk Island</option>
                    <option value={163}>Northern Mariana Islands</option>
                    <option value={164}>Norwegians</option>
                    <option value={165}>Omani</option>
                    <option value={166}>Pakistanis</option>
                    <option value={167}>Palauans</option>
                    <option value={168}>Palestinians</option>
                    <option value={169}>Panamanians</option>
                    <option value={170}>Papua New Guineans</option>
                    <option value={171}>Paraguayans</option>
                    <option value={172}>Peruvians</option>
                    <option value={173}>Philippinos</option>
                    <option value={174}>Pitcairn Island</option>
                    <option value={175}>Poles</option>
                    <option value={176}>Portuguese</option>
                    <option value={177}>Puerto Ricans</option>
                    <option value={178}>Qatari</option>
                    <option value={179}>Réunionnais</option>
                    <option value={180}>Romanians</option>
                    <option value={181}>Russians</option>
                    <option value={182}>Rwandans</option>
                    <option value={183}>Saint Helena</option>
                    <option value={184}>Saint Kitts and Nevis</option>
                    <option value={185}>Saint Lucians</option>
                    <option value={186}>Saint Pierre and Miquelon</option>
                    <option value={187}>
                      Saint Vincent And The Grenadines
                    </option>
                    <option value={188}>Samoans</option>
                    <option value={189}>San Marino</option>
                    <option value={190}>São Tomé and Príncipe</option>
                    <option value={191}>Saudis</option>
                    <option value={192}>Senegalese</option>
                    <option value={193}>Serbs</option>
                    <option value={194}>Seychellois</option>
                    <option value={195}>Sierra Leoneans</option>
                    <option value={196}>Singaporeans</option>
                    <option value={197}>Slovaks</option>
                    <option value={198}>Slovenes</option>
                    <option value={199}>Smaller Territories of the UK</option>
                    <option value={200}>Solomon Islanders</option>
                    <option value={201}>Somalis</option>
                    <option value={202}>South Africans</option>
                    <option value={203}>South Georgia</option>
                    <option value={204}>South Sudan</option>
                    <option value={205}>Spaniards</option>
                    <option value={206}>Sri Lankans</option>
                    <option value={207}>Sudanese</option>
                    <option value={208}>Surinamese</option>
                    <option value={209}>Svalbard And Jan Mayen Islands</option>
                    <option value={210}>Swazi</option>
                    <option value={211}>Swedes</option>
                    <option value={212}>Swiss</option>
                    <option value={213}>Syrians</option>
                    <option value={214}>Taiwanese</option>
                    <option value={215}>Tajik</option>
                    <option value={216}>Tanzanians</option>
                    <option value={217}>Thais</option>
                    <option value={218}>Togolese</option>
                    <option value={219}>Tokelau</option>
                    <option value={220}>Tongans</option>
                    <option value={221}>Trinidadians</option>
                    <option value={222}>Tunisians</option>
                    <option value={223}>Turks</option>
                    <option value={224}>Turkmenistan</option>
                    <option value={225}>Turks And Caicos Islands</option>
                    <option value={226}>Tuvaluans</option>
                    <option value={227}>Ugandans</option>
                    <option value={228}>Ukrainians</option>
                    <option value={229}>Emirati</option>
                    <option value={230}>British</option>
                    <option value={231}>Americans</option>
                    <option value={232}>Americans</option>
                    <option value={233}>Uruguayans</option>
                    <option value={234}>Uzbeks</option>
                    <option value={235}>Vanuatuans</option>
                    <option value={236}>Vatican City State (Holy See)</option>
                    <option value={237}>Venezuelans</option>
                    <option value={238}>Vietnamese</option>
                    <option value={239}>Virgin Islands (British)</option>
                    <option value={240}>Virgin Islands (US)</option>
                    <option value={241}>Wallis And Futuna Islands</option>
                    <option value={242}>Western Sahara</option>
                    <option value={243}>Yemenis</option>
                    <option value={244}>Yugoslavian</option>
                    <option value={245}>Zambians</option>
                    <option value={246}>Zimbabweans</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Date of Birth</label>
                  <input
                    className="form-control"
                    id="date_of_birth"
                    placeholder="Date of Birth"
                    autoComplete="off"
                    name="date_of_birth"
                    type="date"
                    defaultValue="2009-06-09"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>National ID</label>
                  <input
                    className="form-control"
                    id="national_id_card_number"
                    placeholder="National ID Card#"
                    name="national_id_card_number"
                    type="text"
                    defaultValue={1234567890}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Phone</label>
                  <input
                    className="form-control"
                    id="phone"
                    placeholder="Phone"
                    name="phone"
                    type="text"
                    defaultValue={+918851454545}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Mobile</label>
                  <input
                    className="form-control"
                    id="mobile_num"
                    placeholder="Mobile Number"
                    name="mobile_num"
                    type="text"
                    defaultValue={+918989898989}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="formrow ">
                  <label htmlFor>Street Address</label>
                  <textarea
                    className="form-control"
                    id="street_address"
                    placeholder="Street Address"
                    name="street_address"
                    cols={50}
                    rows={10}
                    defaultValue={"Dummy Street Address 123 Delhi"}
                  />
                </div>
              </div>
            </div>
            <hr />
            <h5>Add Video Profile</h5>
            <div className="row">
              <div className="col-md-12" id="video_link_id">
                <div className="formrow ">
                  <label htmlFor>
                    Video Link - sample:
                    https://www.youtube.com/embed/538cRSPrwZU
                  </label>
                  <textarea
                    className="form-control"
                    id="video_link"
                    placeholder="Video Link"
                    name="video_link"
                    cols={50}
                    rows={10}
                    defaultValue={"https://www.youtube.com/embed/538cRSPrwZU"}
                  />
                </div>
              </div>
            </div>
            <hr />
            <h5>Career Information</h5>
            <div className="row">
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Job Experience</label>
                  <select
                    className="form-control"
                    id="job_experience_id"
                    name="job_experience_id"
                  >
                    <option value>Select Experience</option>
                    <option value={11}>Fresh</option>
                    <option value={12}>Less Than 1 Year</option>
                    <option value={1}>1 Year</option>
                    <option value={3}>2 Year</option>
                    <option value={4}>3 Year</option>
                    <option value={5}>4 Year</option>
                    <option value={6}>5 Year</option>
                    <option value={7} selected="selected">
                      6 Year
                    </option>
                    <option value={8}>7 Year</option>
                    <option value={9}>8 Year</option>
                    <option value={10}>9 Year</option>
                    <option value={2}>10 Year</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Career Level</label>
                  <select
                    className="form-control"
                    id="career_level_id"
                    name="career_level_id"
                  >
                    <option value>Select Career Level</option>
                    <option value={1}>Department Head</option>
                    <option value={2}>Entry Level</option>
                    <option value={3} selected="selected">
                      Experienced Professional
                    </option>
                    <option value={4}>
                      GM / CEO / Country Head / President
                    </option>
                    <option value={5}>Intern/Student</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Select Industry</label>
                  <select
                    className="form-control"
                    id="industry_id"
                    name="industry_id"
                  >
                    <option value>Select Industry</option>
                    <option value={8}>Accounting/Taxation</option>
                    <option value={7}>Advertising/PR</option>
                    <option value={13}>Agriculture/Fertilizer/Pesticide</option>
                    <option value={53}>Apparel/Clothing</option>
                    <option value={33}>Architecture/Interior Design</option>
                    <option value={20}>Arts / Entertainment</option>
                    <option value={31}>AutoMobile</option>
                    <option value={58}>Aviation</option>
                    <option value={3}>Banking/Financial Services</option>
                    <option value={34}>BPO</option>
                    <option value={21}>Broadcasting</option>
                    <option value={22}>Business Development</option>
                    <option value={30}>Call Center</option>
                    <option value={12}>Chemicals</option>
                    <option value={17}>Construction/Cement/Metals</option>
                    <option value={49}>Consultants</option>
                    <option value={41}>Courier/Logistics</option>
                    <option value={57}>Distribution and Logistics</option>
                    <option value={11}>Education/Training</option>
                    <option value={28}>Electronics</option>
                    <option value={29}>Engineering</option>
                    <option value={35}>Event Management</option>
                    <option value={32}>Fashion</option>
                    <option value={4}>Fast Moving Consumer Goods (FMCG)</option>
                    <option value={54}>Food &amp; Beverages</option>
                    <option value={36}>Gems &amp; Jewelery</option>
                    <option value={37}>Government</option>
                    <option value={38}>Health &amp; Fitness</option>
                    <option value={39}>Healthcare/Hospital/Medical</option>
                    <option value={40}>Hospitality</option>
                    <option value={15}>Hotel Management / Restaurants</option>
                    <option value={48}>
                      Importers/ Distributors/Exporters
                    </option>
                    <option value={1} selected="selected">
                      Information Technology
                    </option>
                    <option value={6}>Insurance / Takaful</option>
                    <option value={52}>Investments</option>
                    <option value={19}>Law Firms/Legal</option>
                    <option value={10}>Manufacturing</option>
                    <option value={18}>Media/Communications</option>
                    <option value={16}>Mining/Oil &amp; Gas/Petroleum</option>
                    <option value={27}>N.G.O./Social Services</option>
                    <option value={50}>Packaging</option>
                    <option value={51}>Personal Care and Services</option>
                    <option value={5}>Pharmaceuticals/Clinical Research</option>
                    <option value={42}>Power/Energy</option>
                    <option value={47}>Project Management</option>
                    <option value={23}>Publishing/Printing</option>
                    <option value={44}>Real Estate/Property</option>
                    <option value={43}>Recruitment/Employment Firms</option>
                    <option value={26}>Retail</option>
                    <option value={45}>Security/Law Enforcement</option>
                    <option value={25}>Services</option>
                    <option value={46}>Shipping/Marine</option>
                    <option value={2}>Telecommunication/ISP</option>
                    <option value={9}>Textiles/Garments</option>
                    <option value={55}>Tiles &amp; Ceramics</option>
                    <option value={24}>Travel/Tourism/Transportation</option>
                    <option value={56}>Warehousing</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="formrow ">
                  <label htmlFor>Functional Area</label>
                  <select
                    className="form-control"
                    id="functional_area_id"
                    name="functional_area_id"
                  >
                    <option value>Select Functional Area</option>
                    <option value={1}>Accountant</option>
                    <option value={2}>
                      Accounts, Finance &amp; Financial Services
                    </option>
                    <option value={3}>Admin</option>
                    <option value={4}>Admin Operation</option>
                    <option value={5}>Administration</option>
                    <option value={6}>Administration Clerical</option>
                    <option value={7}>Advertising</option>
                    <option value={8}>Advertising</option>
                    <option value={9}>Advertisment</option>
                    <option value={10}>Architects &amp; Construction</option>
                    <option value={11}>Architecture</option>
                    <option value={12}>Bank Operation</option>
                    <option value={13}>Business Development</option>
                    <option value={14}>Business Management</option>
                    <option value={15}>Business Systems Analyst</option>
                    <option value={16}>Clerical</option>
                    <option value={17}>
                      Client Services &amp; Customer Support
                    </option>
                    <option value={18}>Computer Hardware</option>
                    <option value={19}>Computer Networking</option>
                    <option value={20}>Consultant</option>
                    <option value={21}>Content Writer</option>
                    <option value={22}>Corporate Affairs</option>
                    <option value={23} selected="selected">
                      Creative Design
                    </option>
                    <option value={24}>Creative Writer</option>
                    <option value={25}>Customer Support</option>
                    <option value={26}>Data Entry</option>
                    <option value={27}>Data Entry Operator</option>
                    <option value={28}>Database Administration (DBA)</option>
                    <option value={29}>Development</option>
                    <option value={30}>Distribution &amp; Logistics</option>
                    <option value={31}>Education &amp; Training</option>
                    <option value={32}>Electronics Technician</option>
                    <option value={33}>Engineering</option>
                    <option value={34}>Engineering Construction</option>
                    <option value={35}>Executive Management</option>
                    <option value={36}>Executive Secretary</option>
                    <option value={37}>Field Operations</option>
                    <option value={38}>Front Desk Clerk</option>
                    <option value={39}>Front Desk Officer</option>
                    <option value={40}>Graphic Design</option>
                    <option value={41}>Hardware</option>
                    <option value={42}>Health &amp; Medicine</option>
                    <option value={43}>Health &amp; Safety</option>
                    <option value={44}>Health Care</option>
                    <option value={45}>Health Related</option>
                    <option value={46}>Hotel Management</option>
                    <option value={47}>Hotel/Restaurant Management</option>
                    <option value={48}>HR</option>
                    <option value={49}>Human Resources</option>
                    <option value={50}>Import &amp; Export</option>
                    <option value={51}>Industrial Production</option>
                    <option value={52}>Installation &amp; Repair</option>
                    <option value={53}>
                      Interior Designers &amp; Architects
                    </option>
                    <option value={54}>Intern</option>
                    <option value={55}>Internship</option>
                    <option value={56}>Investment Operations</option>
                    <option value={57}>IT Security</option>
                    <option value={58}>IT Systems Analyst</option>
                    <option value={59}>Legal &amp; Corporate Affairs</option>
                    <option value={60}>Legal Affairs</option>
                    <option value={61}>Legal Research</option>
                    <option value={62}>Logistics &amp; Warehousing</option>
                    <option value={63}>Maintenance/Repair</option>
                    <option value={64}>Management Consulting</option>
                    <option value={65}>
                      Management Information System (MIS)
                    </option>
                    <option value={66}>Managerial</option>
                    <option value={67}>Manufacturing</option>
                    <option value={68}>Manufacturing &amp; Operations</option>
                    <option value={69}>Marketing</option>
                    <option value={70}>Marketing</option>
                    <option value={71}>Media - Print &amp; Electronic</option>
                    <option value={72}>Media &amp; Advertising</option>
                    <option value={73}>Medical</option>
                    <option value={74}>Medicine</option>
                    <option value={75}>Merchandising</option>
                    <option value={76}>
                      Merchandising &amp; Product Management
                    </option>
                    <option value={77}>
                      Monitoring &amp; Evaluation (M&amp;E)
                    </option>
                    <option value={78}>Network Administration</option>
                    <option value={79}>Network Operation</option>
                    <option value={80}>Online Advertising</option>
                    <option value={81}>Online Marketing</option>
                    <option value={82}>Operations</option>
                    <option value={83}>Planning</option>
                    <option value={84}>Planning &amp; Development</option>
                    <option value={85}>PR</option>
                    <option value={86}>Print Media</option>
                    <option value={87}>Printing</option>
                    <option value={88}>Procurement</option>
                    <option value={89}>Product Developer</option>
                    <option value={90}>Product Development</option>
                    <option value={91}>Product Development</option>
                    <option value={92}>Product Management</option>
                    <option value={93}>Production</option>
                    <option value={94}>Production &amp; Quality Control</option>
                    <option value={95}>Project Management</option>
                    <option value={96}>Project Management Consultant</option>
                    <option value={97}>Public Relations</option>
                    <option value={98}>QA</option>
                    <option value={99}>QC</option>
                    <option value={100}>Qualitative Research</option>
                    <option value={101}>Quality Assurance (QA)</option>
                    <option value={102}>Quality Control</option>
                    <option value={103}>Quality Inspection</option>
                    <option value={104}>Recruiting</option>
                    <option value={105}>Recruitment</option>
                    <option value={106}>Repair &amp; Overhaul</option>
                    <option value={107}>
                      Research &amp; Development (R&amp;D)
                    </option>
                    <option value={108}>Research &amp; Evaluation</option>
                    <option value={109}>Research &amp; Fellowships</option>
                    <option value={110}>Researcher</option>
                    <option value={111}>Restaurant Management</option>
                    <option value={112}>Retail</option>
                    <option value={113}>Retail &amp; Wholesale</option>
                    <option value={114}>Retail Buyer</option>
                    <option value={115}>Retail Buying</option>
                    <option value={116}>Retail Merchandising</option>
                    <option value={117}>Safety &amp; Environment</option>
                    <option value={118}>Sales</option>
                    <option value={119}>
                      Sales &amp; Business Development
                    </option>
                    <option value={120}>Sales Support</option>
                    <option value={121}>
                      Search Engine Optimization (SEO)
                    </option>
                    <option value={122}>
                      Secretarial, Clerical &amp; Front Office
                    </option>
                    <option value={123}>Security</option>
                    <option value={124}>Security &amp; Environment</option>
                    <option value={125}>Security Guard</option>
                    <option value={126}>SEM</option>
                    <option value={127}>SMO</option>
                    <option value={128}>Software &amp; Web Development</option>
                    <option value={129}>Software Engineer</option>
                    <option value={130}>Software Testing</option>
                    <option value={131}>Stores &amp; Warehousing</option>
                    <option value={132}>Supply Chain</option>
                    <option value={133}>Supply Chain Management</option>
                    <option value={134}>Systems Analyst</option>
                    <option value={135}>
                      Teachers/Education, Training &amp; Development
                    </option>
                    <option value={136}>Technical Writer</option>
                    <option value={137}>Tele Sale Representative</option>
                    <option value={138}>Telemarketing</option>
                    <option value={139}>Training &amp; Development</option>
                    <option value={140}>
                      Transportation &amp; Warehousing
                    </option>
                    <option value={141}>TSR</option>
                    <option value={142}>Typing</option>
                    <option value={143}>Warehousing</option>
                    <option value={144}>Web Developer</option>
                    <option value={145}>Web Marketing</option>
                    <option value={146}>Writer</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="formrow ">
                  <label htmlFor>Current Salary</label>
                  <input
                    className="form-control"
                    id="current_salary"
                    placeholder="Current Salary"
                    name="current_salary"
                    type="text"
                    defaultValue={10000}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="formrow ">
                  <label htmlFor>Expected Salary</label>
                  <input
                    className="form-control"
                    id="expected_salary"
                    placeholder="Expected Salary"
                    name="expected_salary"
                    type="text"
                    defaultValue={15000}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="formrow ">
                  <label htmlFor>Salary Currency</label>
                  <input
                    className="form-control"
                    id="salary_currency"
                    placeholder="Salary Currency"
                    autoComplete="off"
                    name="salary_currency"
                    type="text"
                    defaultValue="INR"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="formrow ">
                  <input
                    type="checkbox"
                    defaultValue={1}
                    name="is_subscribed"
                    defaultChecked='"checked"'
                  />
                  Subscribe to news letter
                </div>
              </div>
              <div className="col-md-12">
                <div className="formrow">
                  <button type="submit" className="btn">
                    Update Profile and Save{" "}
                    <i
                      className="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </form>
          <hr />
        </div>
      </div>
      <Summary />
      <CurriculumVitae />
    </>
  );
}
export default AccountInformation;
