import MyMessage from "../../components/myMessage/MyMessage";

function MyMessagePage() {
  return (
    <>
      <MyMessage />
    </>
  );
}
export default MyMessagePage;
