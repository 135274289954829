import { Col, Nav, Row, Tab } from "react-bootstrap";

import img1 from "../../assets/img/image/img-1.png";
import img2 from "../../assets/img/image/img-2.png";
import img3 from "../../assets/img/image/img-3.png";

function MyMessage() {
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={4}>
            <Nav variant="pills" className="flex-column flex-column-2">
              <Nav.Item className="message-history">
                <Nav.Link eventKey="first" className="message-grid">
                  <div className="image">
                    <img
                      src={img1}
                      alt="Hotel Tirupati Classic"
                      title="Hotel Tirupati Classic"
                    />
                  </div>
                  <div className="user-name">
                    <div className="author">
                      {" "}
                      <span>Hotel Tirupati Classic</span>
                    </div>
                    <div className="count-messages">0</div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="message-history">
                <Nav.Link eventKey="second" className="message-grid">
                  <div className="image">
                    <img
                      src={img2}
                      alt="AutoSoft Dynamics"
                      title="AutoSoft Dynamics"
                    />
                  </div>
                  <div class="user-name">
                    <div class="author">
                      {" "}
                      <span>AutoSoft Dynamics</span>
                    </div>
                    <div class="count-messages">0</div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="message-history">
                <Nav.Link eventKey="third" className="message-grid">
                  <div className="image">
                    <img
                      src={img3}
                      alt="Abaris Softech Pvt Ltd"
                      title="Abaris Softech Pvt Ltd"
                    />
                  </div>

                  <div className="user-name">
                    <div className="author">
                      {" "}
                      <span>Abaris Softech Pvt Ltd</span>
                    </div>
                    <div className="count-messages">0</div>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="message-details message-details-2">
                  <h4> </h4>
                  <div id="append_messages">
                    <ul className="messages message1">
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          hi, there?
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          fghfh
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          hi
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="my-message clearfix tab1">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          hey
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="my-message clearfix tab1">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          yes
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="my-message clearfix tab1">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          hi
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="my-message clearfix tab1">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          zbxvb
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          hi
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="my-message clearfix tab1">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          ajkldfjlaksdjf
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="my-message clearfix tab1">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          hi tajammal
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          ye i am here
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="my-message clearfix tab1">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          test
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="my-message clearfix tab1">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          this test message
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab1">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img1}
                            alt="Hotel Tirupati Classic"
                            title="Hotel Tirupati Classic"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          this is test reply
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="chat-form" type="post">
                      <form className="form-inline" noValidate="novalidate">
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="sQsH3AlCKrFbaFecixp1Y2EUtqxZqkm6TR5yFyJu"
                        />{" "}
                        <div className="form-group">
                          <div className="input-wrap">
                            <input
                              type="hidden"
                              name="company_id"
                              defaultValue={1}
                            />
                            <textarea
                              className="form-control"
                              name="message"
                              placeholder="Type Your Message here.."
                              defaultValue={""}
                            />
                            <div className="input-group-prepend">
                              {" "}
                              <button
                                type="submit"
                                className="input-group-text"
                                id="inputGroupPrepend3"
                                fdprocessedid="0us5ni"
                              >
                                <i
                                  className="fas fa-paper-plane"
                                  aria-hidden="true"
                                />
                              </button>{" "}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="message-details message-details-2">
                  <h4> </h4>
                  <div id="append_messages">
                    <ul className="messages message2">
                      <li className="my-message clearfix tab2">
                        <figure className="profile-picture">
                          <img
                            src={img2}
                            style={{ maxHeight: 100 }}
                            alt="Daniyal Khan"
                            title="Daniyal Khan"
                          />
                        </figure>
                        <div className="message">
                          {" "}
                          testete
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="chat-form" type="post">
                      <form className="form-inline" noValidate="novalidate">
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="sQsH3AlCKrFbaFecixp1Y2EUtqxZqkm6TR5yFyJu"
                        />{" "}
                        <div className="form-group">
                          <div className="input-wrap">
                            <input
                              type="hidden"
                              name="company_id"
                              defaultValue={2}
                            />
                            <textarea
                              className="form-control"
                              name="message"
                              placeholder="Type Your Message here.."
                              defaultValue={""}
                            />
                            <div className="input-group-prepend">
                              {" "}
                              <button
                                type="submit"
                                className="input-group-text"
                                id="inputGroupPrepend3"
                                fdprocessedid="4653up"
                              >
                                <i
                                  className="fas fa-paper-plane"
                                  aria-hidden="true"
                                />
                              </button>{" "}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="message-details message-details-2">
                  <h4> </h4>
                  <div id="append_messages">
                    <ul className="messages message9">
                      <li className="friend-message clearfix tab9">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img3}
                            alt="Abaris Softech Pvt Ltd"
                            title="Abaris Softech Pvt Ltd"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          this is test
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab9">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img3}
                            alt="Abaris Softech Pvt Ltd"
                            title="Abaris Softech Pvt Ltd"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          hi how are you
                          <div className="time">
                            <i className="fa fa-clock-o" />2 years ago
                          </div>
                        </div>
                      </li>
                      <li className="friend-message clearfix tab9">
                        <figure className="profile-picture">
                          {" "}
                          <img
                            src={img3}
                            alt="Abaris Softech Pvt Ltd"
                            title="Abaris Softech Pvt Ltd"
                          />{" "}
                        </figure>
                        <div className="message">
                          {" "}
                          ewr
                          <div className="time">
                            <i className="fa fa-clock-o" />1 day ago
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="chat-form" type="post">
                      <form className="form-inline" noValidate="novalidate">
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="sQsH3AlCKrFbaFecixp1Y2EUtqxZqkm6TR5yFyJu"
                        />{" "}
                        <div className="form-group">
                          <div className="input-wrap">
                            <input
                              type="hidden"
                              name="company_id"
                              defaultValue={9}
                            />
                            <textarea
                              className="form-control"
                              name="message"
                              placeholder="Type Your Message here.."
                              defaultValue={""}
                            />
                            <div className="input-group-prepend">
                              {" "}
                              <button
                                type="submit"
                                className="input-group-text"
                                id="inputGroupPrepend3"
                                fdprocessedid="ous6ra"
                              >
                                <i
                                  className="fas fa-paper-plane"
                                  aria-hidden="true"
                                />
                              </button>{" "}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}
export default MyMessage;
