import { Link, NavLink } from "react-router-dom";

function Aside() {
  return (
    <>
      <div className="usernavwrap">
        <div className="switchbox">
          <div className="txtlbl">
            Immediate Available{" "}
            <i
              className="fas fa-info-circle"
              data-toggle="popover"
              data-trigger="hover"
              data-placement="top"
              data-content="Are you immediate available?"
              data-original-title="Are you immediate available?"
              title="Are you immediate available?"
            />
          </div>
          <div className>
            <label className="switch switch-green">
              {" "}
              <input
                type="checkbox"
                name="is_immediate_available"
                id="is_immediate_available"
                className="switch-input"
              />
              <span className="switch-label" data-on="On" data-off="Off" />{" "}
              <span className="switch-handle" />{" "}
            </label>
          </div>
          <div className="clearfix" />
        </div>
        <ul className="usernavdash">
          <li className="active">
            <Link to="/Candidate">
              <i className="fas fa-tachometer" /> Dashboard
            </Link>
          </li>
          <li className>
            <NavLink to="my-profile">
              <i className="fas fa-pencil" /> Edit Profile
            </NavLink>
          </li>
          <li>
            <NavLink to="/view-public-profile">
              <i className="fas fa-eye" /> View Public Profile
            </NavLink>
          </li>
          <li className>
            <NavLink to="my-job-applications">
              <i className="fas fa-desktop" /> My Job Applications
            </NavLink>
          </li>
          <li className>
            <NavLink to="my-favourite-jobs">
              <i className="fas fa-heart" /> My Favourite Jobs
            </NavLink>
          </li>
          <li className>
            <NavLink to="my-alerts">
              <i className="fas fa-bullhorn" /> My Job Alerts
            </NavLink>
          </li>
          <li>
            <NavLink to="my-profile">
              <i className="fas fa-file" /> Manage Resume
            </NavLink>
          </li>
          <li className>
            <NavLink to="my-messages">
              <i className="fas fa-envelope" /> My Messages
            </NavLink>
          </li>
          <li className>
            <NavLink to="my-followings">
              <i className="fas fa-user" /> My Followings
            </NavLink>
          </li>
          <li>
            <Link to="#"l>
              <i className="fas fa-sign-out" /> Logout
            </Link>
            <form
              id="logout-form"
              action="https://hsalghanimdelivery.com/logout"
              method="POST"
              style={{ display: "none" }}
            >
              <input
                type="hidden"
                name="_token"
                defaultValue="746hfhj4MJJghyjekabWWNWJmEymXqgOlV02nK9Y"
              />
            </form>
          </li>
        </ul>
      </div>

      <div className="row">
        <div className="col-md-12">
          <img src="https://www.sharjeelanjum.com/demos/jobsportal-update/images/large-ad.jpg" />
        </div>
      </div>
    </>
  );
}

export default Aside;
