function ViewPublicProfile() {
  return (
    <>
      <div className="listpgWraper">
        <div className="container">
          <div className="usercoverimg">
            <img
              src="https://hsalghanimdelivery.com/user_images/-1614188466-122.jpg"
              alt="Daniyal Khan"
              title="Daniyal Khan"
            />
            <div className="userMaininfo">
              <div className="userPic">
                <img
                  src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                  alt="Daniyal Khan"
                  title="Daniyal Khan"
                />
              </div>
              <div className="title">
                <h3>Daniyal Khan</h3>
                <div className="desi">
                  <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                  Saharanpur, Uttar Pradesh, India
                </div>
                <div className="membersinc">
                  <i className="fa fa-history" aria-hidden="true" /> Member
                  Since, Sep 19, 2018
                </div>
              </div>
            </div>
          </div>
          {/* Buttons */}
          <div className="userlinkstp"></div>
          {/* Job Detail start */}
          <div className="row">
            <div className="col-md-8">
              {/* About Employee start */}
              <div className="job-header">
                <div className="contentbox">
                  <h3>About me</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam fringilla at enim quis tincidunt. Nulla condimentum
                    dapibus efficitur. In massa felis, fringilla at urna
                    vestibulum, mattis malesuada metus. Integer id lorem tortor.
                    Pellentesque hendrerit sapien sit amet finibus pretium.
                    Fusce eu sagittis orci. Quisque urna velit, facilisis
                    interdum nisl nec, commodo tristique leo. Maecenas turpis
                    augue, vulputate ac lorem in, euismod euismod tortor.
                    Phasellus vitae lacinia est, ut porta leo. Morbi sit amet
                    quam in risus gravida mattis. Suspendisse sodales massa et
                    odio mollis, id ultricies ipsum semper. Duis pretium
                    vestibulum dui at scelerisque.
                  </p>
                </div>
                <div className="ptsklbx">
                  <h3>Skills</h3>
                  <div id="skill_div">
                    <ul className="profileskills row">
                      <li className="col-md-4" id="skill_14">
                        <div className="skillbox">
                          Adobe Illustrator
                          <span className="text text-success">8 Year</span>
                        </div>
                      </li>
                      <li className="col-md-4" id="skill_15">
                        <div className="skillbox">
                          Adobe Photoshop
                          <span className="text text-success">8 Year</span>
                        </div>
                      </li>
                      <li className="col-md-4" id="skill_16">
                        <div className="skillbox">
                          Communication Skills
                          <span className="text text-success">5 Year</span>
                        </div>
                      </li>
                      <li className="col-md-4" id="skill_17">
                        <div className="skillbox">
                          CSS
                          <span className="text text-success">8 Year</span>
                        </div>
                      </li>
                      <li className="col-md-4" id="skill_18">
                        <div className="skillbox">
                          HTML
                          <span className="text text-success">8 Year</span>
                        </div>
                      </li>
                      <li className="col-md-4" id="skill_19">
                        <div className="skillbox">
                          JavaScript
                          <span className="text text-success">5 Year</span>
                        </div>
                      </li>
                      <li className="col-md-4" id="skill_20">
                        <div className="skillbox">
                          Jquery
                          <span className="text text-success">4 Year</span>
                        </div>
                      </li>
                      <li className="col-md-4" id="skill_21">
                        <div className="skillbox">
                          WordPress
                          <span className="text text-success">3 Year</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Profile Video start */}
              <div className="job-header">
                <div className="contentbox">
                  <h3>Video Profile</h3>
                  <iframe
                    src="https://www.youtube.com/embed/538cRSPrwZU"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
              {/* Experience start */}
              <div className="job-header">
                <div className="contentbox">
                  <h3>Experience</h3>
                  <div className id="experience_div">
                    <ul className="experienceList">
                      <li>
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              src="https://hsalghanimdelivery.com/images/work-experience.png"
                              alt="work experience"
                            />
                          </div>
                          <div className="col-md-10">
                            <h4>My Test Company 1 | Alexander City</h4>
                            <div className="row">
                              <div className="col-md-6">Web Designer</div>
                              <div className="col-md-6">
                                From 04 Jan, 2016 - 29 Dec, 2017
                              </div>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nullam fringilla at enim quis tincidunt.
                              Nulla condimentum dapibus efficitur. In massa
                              felis, fringilla at urna vestibulum, mattis
                              malesuada metus.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              src="https://hsalghanimdelivery.com/images/work-experience.png"
                              alt="work experience"
                            />
                          </div>
                          <div className="col-md-10">
                            <h4>My Test Company 2 | Kennesaw</h4>
                            <div className="row">
                              <div className="col-md-6">
                                Senior Frontend Developer
                              </div>
                              <div className="col-md-6">
                                From 08 Jan, 2018 - Currently working
                              </div>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nullam fringilla at enim quis tincidunt.
                              Nulla condimentum dapibus efficitur. In massa
                              felis, fringilla at urna vestibulum, mattis
                              malesuada metus. Integer id lorem tortor.
                              Pellentesque hendrerit sapien sit amet finibus
                              pretium.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {/* Candidate Detail start */}
              <div className="job-header">
                <div className="jobdetail">
                  <h3>Candidate Detail</h3>
                  <ul className="jbdetail">
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Is Email Verified</div>
                      <div className="col-md-6 col-xs-6">
                        <span>Yes</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">
                        Immediate Available
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <span>No</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Age</div>
                      <div className="col-md-6 col-xs-6">
                        <span>13 Years</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Gender</div>
                      <div className="col-md-6 col-xs-6">
                        <span>Male</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Marital Status</div>
                      <div className="col-md-6 col-xs-6">
                        <span>Single</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Experience</div>
                      <div className="col-md-6 col-xs-6">
                        <span>6 Year</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Career Level</div>
                      <div className="col-md-6 col-xs-6">
                        <span>Experienced Professional</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Current Salary</div>
                      <div className="col-md-6 col-xs-6">
                        <span className="permanent">10000 INR</span>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-6 col-xs-6">Expected Salary</div>
                      <div className="col-md-6 col-xs-6">
                        <span className="freelance">15000 INR</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Education start */}
              <div className="job-header">
                <div className="contentbox">
                  <h3>Education</h3>
                  <div className id="education_div">
                    <ul className="educationList">
                      <li>
                        <h4>Matriculation/O-Level - Matric in Science</h4>
                        <div className="date">2012 - Bouse</div>
                        <h5>Matric</h5>
                        <p>
                          Computer Engineering
                          <br />
                          My test Institute
                        </p>
                        <div className="clearfix" />
                      </li>
                      <li>
                        <h4>
                          Intermediate/A-Level - Intermediate in Computer
                          Science
                        </h4>
                        <div className="date">2014 - San Anselmo</div>
                        <h5>ICS</h5>
                        <p>
                          Computer Engineering, Computer Graphics
                          <br />
                          My test Institute 2
                        </p>
                        <div className="clearfix" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="job-header">
                <div className="jobdetail">
                  <h3>Languages</h3>
                  <div id="language_div">
                    <div className="col-mid-12">
                      <table className="table table-bordered table-condensed">
                        <tbody>
                          <tr id="language_10">
                            <td>
                              <span className="text text-success">English</span>
                            </td>
                            <td>
                              <span className="text text-success">Expert</span>
                            </td>
                          </tr>
                          <tr id="language_11">
                            <td>
                              <span className="text text-success">Turkish</span>
                            </td>
                            <td>
                              <span className="text text-success">
                                Intermediate
                              </span>
                            </td>
                          </tr>
                          <tr id="language_12">
                            <td>
                              <span className="text text-success">Arabic</span>
                            </td>
                            <td>
                              <span className="text text-success">
                                Intermediate
                              </span>
                            </td>
                          </tr>
                          <tr id="language_13">
                            <td>
                              <span className="text text-success">Chinese</span>
                            </td>
                            <td>
                              <span className="text text-success">
                                Beginner
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Portfolio start */}
          <div className="job-header">
            <div className="contentbox">
              <h3>Portfolio</h3>
              <div className id="projects_div">
                <ul className="row userPortfolio">
                  <li className="col-md-3">
                    <div className="imgbox">
                      <img
                        src="https://hsalghanimdelivery.com/project_images/thumb/my-test-1-xczub-967.jpg"
                        alt
                        title
                      />
                      <div className="itemHover">
                        <div className="zoombox">
                          <a
                            href="http://www.google.com"
                            title="My test 1"
                            className="item-zoom fancybox-effects-a"
                          >
                            <i className="fa fa-search" aria-hidden="true" />
                          </a>{" "}
                        </div>
                        <div className="infoItem">
                          <div className="itemtitle">
                            <h5>My test 1</h5>
                            <p>
                              15 Aug, 2021 - 15 Aug, 2021
                              <br />
                              Lorem ipsum dolor sit ame...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <div className="imgbox">
                      <img
                        src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                        alt
                        title
                      />
                      <div className="itemHover">
                        <div className="zoombox">
                          <a
                            href="http://www.yourdomain.com"
                            title="Test Project 2"
                            className="item-zoom fancybox-effects-a"
                          >
                            <i className="fa fa-search" aria-hidden="true" />
                          </a>{" "}
                        </div>
                        <div className="infoItem">
                          <div className="itemtitle">
                            <h5>Test Project 2</h5>
                            <p>
                              15 Aug, 2021 - 15 Aug, 2021
                              <br />
                              Lorem ipsum dolor sit ame...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <div className="imgbox">
                      <img
                        src="https://hsalghanimdelivery.com/project_images/thumb/test-project-3-o2whp-818.jpg"
                        alt
                        title
                      />
                      <div className="itemHover">
                        <div className="zoombox">
                          <a
                            href="http://www.yourdomain.com"
                            title="Test Project 3"
                            className="item-zoom fancybox-effects-a"
                          >
                            <i className="fa fa-search" aria-hidden="true" />
                          </a>{" "}
                        </div>
                        <div className="infoItem">
                          <div className="itemtitle">
                            <h5>Test Project 3</h5>
                            <p>
                              15 Aug, 2021 - 15 Aug, 2021
                              <br />
                              Lorem ipsum dolor sit ame...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewPublicProfile;
