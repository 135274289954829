import { Link } from "react-router-dom";

function ViewDetails() {
  return (
    <>
      <div className="listpgWraper">
        <div className="container">
          <div className="row jobPagetitle">
            <div className="col-lg-9">
              <div className="jobinfo">
                <h2>IOS Developer - Abaris Softech Pvt Ltd</h2>
                <div className="ptext">Date Posted: Jul 27, 2018</div>
                <div className="salary">
                  Monthly: <strong>60000 PKR - 90000 PKR</strong>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="jobButtons applybox">
                <a href="#" className="btn apply applied">
                  <i className="fas fa-paper-plane" />{" "}
                  Already Applied
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-7">
              {/* Job Header start */}
              <div className="job-header">
                {/* Job Detail start */}
                <div className="jobmainreq">
                  <div className="jobdetail">
                    <h3>
                      <i className="fa fa-align-left" aria-hidden="true" /> Job
                      Detail
                    </h3>
                    <ul className="jbdetail">
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Location:</div>
                        <div className="col-md-8 col-xs-7">
                          <span>Auburn, Alabama, United States of America</span>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Company:</div>
                        <div className="col-md-8 col-xs-7">
                          <a href="https://hsalghanimdelivery.com/company/9">
                            Abaris Softech Pvt Ltd
                          </a>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Type:</div>
                        <div className="col-md-8 col-xs-7">
                          <span className="permanent">Full Time/Permanent</span>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Shift:</div>
                        <div className="col-md-8 col-xs-7">
                          <span className="freelance">First Shift (Day)</span>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Career Level:</div>
                        <div className="col-md-8 col-xs-7">
                          <span>Experienced Professional</span>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Positions:</div>
                        <div className="col-md-8 col-xs-7">
                          <span>4</span>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Experience:</div>
                        <div className="col-md-8 col-xs-7">
                          <span>4 Year</span>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Gender:</div>
                        <div className="col-md-8 col-xs-7">
                          <span>Male</span>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Degree:</div>
                        <div className="col-md-8 col-xs-7">
                          <span>Bachelors</span>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-4 col-xs-5">Apply Before:</div>
                        <div className="col-md-8 col-xs-7">
                          <span>Jun 29, 2023</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className="jobButtons">
                  <Link
                    to="/email-to-friend"
                    className="btn"
                  >
                    <i className="fas fa-envelope" aria-hidden="true" /> Email
                    to Friend
                  </Link>
                  <a
                    href="https://hsalghanimdelivery.com/remove-from-favourite-job/ios-developer-2"
                    className="btn"
                  >
                    <i className="fas fa-floppy-o" aria-hidden="true" />{" "}
                    Favourite Job{" "}
                  </a>{" "}
                  <Link to="/report-abuse" className="btn report">
                    <i
                      className="fas fa-exclamation-triangle"
                      aria-hidden="true"
                    />{" "}
                    Report Abuse
                  </Link>
                </div>
              </div>
              {/* Job Description start */}
              <div className="job-header">
                <div className="contentbox">
                  <h3>
                    <i className="fas fa-file-text" aria-hidden="true" /> Job
                    Description
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin fermentum condimentum mauris, non posuere urna
                    consectetur quis. Suspendisse semper eu eros eget convallis.
                    Etiam mattis blandit nulla, non venenatis risus varius vel.
                    Morbi fringilla dignissim elit id blandit. Pellentesque vel
                    luctus felis. Vestibulum eros nibh, consequat ut felis in,
                    vehicula lobortis quam. Duis diam mauris, convallis in risus
                    in, gravida hendrerit lacus. Donec euismod accumsan sem et
                    aliquam. Duis a velit eget urna mattis ultricies. Aliquam
                    nibh ipsum, aliquet nec sollicitudin non, fermentum nec
                    diam. Vestibulum ac urna vehicula, dapibus dui quis, aliquet
                    neque. Sed ac tristique purus. Vestibulum tempor, erat eu
                    porta tempor, erat ipsum cursus dui, eu tempus mauris leo id
                    mi. Sed ultrices sollicitudin vehicula. Proin in ullamcorper
                    massa.
                    <br />
                    <br />
                  </p>
                  <ul>
                    <li>this 1</li>
                    <li>this is 2</li>
                    <li>this is 3</li>
                  </ul>
                  <p />
                </div>
              </div>
              <div className="job-header benefits">
                <div className="contentbox">
                  <h3>
                    <i className="fa fa-file-text" aria-hidden="true" />{" "}
                    Benefits
                  </h3>
                  <p />
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </li>
                    <li>Fusce id diam vitae lacus consectetur placerat.</li>
                    <li>Nulla consequat metus ac ante semper vehicula.</li>
                    <li>Vivamus eleifend elit ut porta hendrerit.</li>
                    <li>
                      Donec suscipit magna eu sapien elementum, sit amet ornare
                      ipsum consequat.
                    </li>
                    <li>Etiam faucibus nisl accumsan commodo fringilla.</li>
                  </ul>
                  <p />
                </div>
              </div>
              <div className="job-header">
                <div className="contentbox">
                  <h3>
                    <i className="fas fa-puzzle-piece" aria-hidden="true" />{" "}
                    Skills Required
                  </h3>
                  <ul className="skillslist">
                    <li>
                      <Link to="/job_skill">CSS</Link>
                    </li>
                    <li>
                      <Link to="/job_skill">HTML</Link>
                    </li>
                    <li>
                      <Link to="/job_skill">JavaScript</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Job Description end */}
            </div>
            {/* related jobs end */}
            <div className="col-lg-5">
              <div className="companyinfo">
                <h3>
                  <i className="fas fa-building" aria-hidden="true" /> Company
                  Overview
                </h3>
                <div className="companylogo">
                  <Link to="/company/abaris-softech-pvt-ltd-9">
                    <img
                      src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                      alt="Abaris Softech Pvt Ltd"
                      title="Abaris Softech Pvt Ltd"
                    />
                  </Link>
                </div>
                <div className="title">
                  <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
                    Abaris Softech Pvt Ltd
                  </a>
                </div>
                <div className="ptext">New Delhi, Delhi, India</div>
                <div className="opening">
                  <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
                    6 Current Jobs Openings
                  </a>
                </div>
                <div className="clearfix" />
                <hr />
                <div className="companyoverview">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin fermentum condimentum mauris, non posuere urna
                    consectetur quis. Suspendisse semper eu eros eget convallis.
                    Etiam mattis blandit nulla, non venenatis risus varius vel.
                    Morbi fringilla dig...{" "}
                    <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
                      Read More
                    </a>
                  </p>
                </div>
              </div>
              {/* related jobs start */}
              <div className="relatedJobs">
                <h3>Related Jobs</h3>
                <ul className="searchList">
                  {/*Job start*/}
                  <li>
                    <div className="jobinfo">
                      <h3>
                        <a
                          href="https://hsalghanimdelivery.com/job/ios-developer-2"
                          title="IOS Developer"
                        >
                          IOS Developer
                        </a>
                      </h3>
                      <div className="companyName">
                        <a
                          href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                          title="Abaris Softech Pvt Ltd"
                        >
                          Abaris Softech Pvt Ltd
                        </a>
                      </div>
                      <div className="location">
                        <span>Auburn</span>
                      </div>
                      <div className="location">
                        <label className="fulltime">Full Time/Permanent</label>
                        <label className="partTime">
                          First Shift (Day)
                        </label>{" "}
                      </div>
                    </div>
                    <div className="clearfix" />
                  </li>
                  {/*Job end*/}
                  {/* Job end */}
                </ul>
              </div>
              {/* Google Map start */}
              <div className="job-header">
                <div className="jobdetail">
                  <h3>
                    <i className="fas fa-map-marker" aria-hidden="true" />{" "}
                    Google Map
                  </h3>
                  <div className="gmap">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d66689.1610893612!2d77.20018446424909!3d28.542205941928287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1670424928238!5m2!1sen!2sin"
                      width={600}
                      height={450}
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewDetails;
