function FavouriteJobs() {
  return (
    <>
      <div className="col-md-9 col-sm-8">
        <div className="myads">
          <h3>Favourite Jobs</h3>
          <ul className="searchList"></ul>
        </div>
      </div>
    </>
  );
}
export default FavouriteJobs;
