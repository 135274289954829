import MyJobAlerts from "../../components/myJobAlerts/MyJobAlerts";

function MyJobAlertsPage() {
  return (
    <>
      <MyJobAlerts />
    </>
  );
}
export default MyJobAlertsPage;
