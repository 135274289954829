function ViewDetailReportAbuse() {
  return (
    <>
      <div className="listpgWraper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="userccount">
                {" "}
                <form
                  method="POST"
                  action="https://hsalghanimdelivery.com/report-abuse/ios-developer-2"
                  acceptCharset="UTF-8"
                >
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="Sz993YfbsV7CQBuptBVffOiXB3VHuM7uxUJBohwH"
                  />
                  <div className="formpanel">
                    {/* Ad Information */}
                    <h5>Report Abuse</h5>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="formrow">
                          <input
                            className="form-control"
                            id="job_url"
                            placeholder="URL"
                            required="required"
                            readOnly="readonly"
                            name="job_url"
                            type="text"
                            defaultValue="https://hsalghanimdelivery.com/job/ios-developer-2"
                            fdprocessedid="fbx4bp"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="formrow">
                          <input
                            className="form-control"
                            id="your_name"
                            placeholder="Your Name"
                            required="required"
                            name="your_name"
                            type="text"
                            defaultValue="Daniyal Khan"
                            fdprocessedid="pk0d3g"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="formrow">
                          <input
                            className="form-control"
                            id="your_email"
                            placeholder="Your Email"
                            required="required"
                            name="your_email"
                            type="text"
                            defaultValue="seeker@hsalghanimdelivery.com"
                            fdprocessedid="6cb24i"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="formrow">
                          <div
                            data-sitekey="6LdLUZMUAAAAAF1F0GWT6nq7nh_mCqyet58GySE3"
                            className="g-recaptcha"
                          >
                            <div style={{ width: 304, height: 78 }}>
                              <div>
                                <iframe
                                  title="reCAPTCHA"
                                  src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6LdLUZMUAAAAAF1F0GWT6nq7nh_mCqyet58GySE3&co=aHR0cHM6Ly9oc2FsZ2hhbmltZGVsaXZlcnkuY29tOjQ0Mw..&hl=en&v=NZrMWHVy58-S9gVvad9HVGxk&size=normal&cb=jhzgbvy472dn"
                                  width={304}
                                  height={78}
                                  role="presentation"
                                  frameBorder={0}
                                  scrolling="no"
                                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
                                />
                              </div>
                              <textarea
                                id="g-recaptcha-response-3"
                                name="g-recaptcha-response"
                                className="g-recaptcha-response"
                                style={{
                                  width: 250,
                                  height: 40,
                                  border: "1px solid rgb(193, 193, 193)",
                                  margin: "10px 25px",
                                  padding: 0,
                                  resize: "none",
                                  display: "none",
                                }}
                                defaultValue={""}
                              />
                            </div>
                            <iframe style={{ display: "none" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <input
                      type="submit"
                      id="post_ad_btn"
                      className="btn"
                      defaultValue="Report"
                      fdprocessedid="ypchj"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewDetailReportAbuse;
