
function Dashboard() {
    return (
        <>
            <div className="profileban">
                <div className="abtuser">
                    <div className="row">
                        <div className="col-lg-2 col-md-2">
                            <div className="uavatar"><img src="https://hsalghanimdelivery.com/admin_assets/no-image.png" alt="Daniyal Khan" title="Daniyal Khan" /></div>
                        </div>
                        <div className="col-lg-10 col-md-10">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h4>Daniyal Khan</h4>
                                    <h6><i className="fas fa-map-marker" aria-hidden="true" /> Saharanpur, Uttar Pradesh, India</h6>
                                </div>
                                <div className="col-lg-5"><div className="editbtbn"><a href="https://hsalghanimdelivery.com/my-profile"><i className="fas fa-pencil-alt" aria-hidden="true" /> Edit Profile</a>
                                </div></div>
                            </div>
                            <ul className="row userdata">
                                <li className="col-lg-6 col-md-6"><i className="fas fa-phone" aria-hidden="true" /> +918851454545</li>
                                <li className="col-lg-6 col-md-6"><i className="fas fa-envelope" aria-hidden="true" /> seeker@hsalghanimdelivery.com</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Dashboard