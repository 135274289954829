import { Link } from "react-router-dom";

const jobData = {
  applicationData: [
    {
      id: "1",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "IOS Developer",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "First Shift (Day)",
      station: "Auburn",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
    {
      id: "2",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "Project Manager",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "First Shift (Day)",
      station: "Kaneohe Station",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
    {
      id: "3",
      img: "https://hsalghanimdelivery.com/company_logos/power-wave-1536854843-603.jpg",
      title: "Dot Developer",
      companyName: "Power Wave",
      shift: "First Shift (Day)",
      station: "Durant",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
    {
      id: "4",
      img: "https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png",
      title: "SEO Expert",
      companyName: "Abaris Softech Pvt Ltd",
      shift: "Second Shift (Afternoon)",
      station: "Casas Adobes",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum mauris, non posuere urna consectetur quis. Suspendisse semper eu...",
      showDetail: "View Details",
    },
  ],
};

function MyJobApplication() {
  return (
    <>
      <div className="col-md-9 col-sm-8">
        <div className="myads">
          <h3>Applied Jobs</h3>
          <ul className="searchList">
            {jobData.applicationData.map((item) => {
              console.log(item);
              return (
                <li key={item.id}>
                  <div className="row">
                    <div className="col-md-8 col-sm-8">
                      <div className="jobimg">
                        <img
                          src={item.img}
                          alt="Abaris Softech Pvt Ltd"
                          title="Abaris Softech Pvt Ltd"
                        />
                      </div>
                      <div className="jobinfo">
                        <h3>
                          <a
                            href="https://hsalghanimdelivery.com/job/ios-developer-2"
                            title="IOS Developer"
                          >
                            {item.title}
                          </a>
                        </h3>
                        <div className="companyName">
                          <a
                            href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                            title="Abaris Softech Pvt Ltd"
                          >
                            {item.companyName}
                          </a>
                        </div>
                        <div className="location">
                          <label className="fulltime" title="First Shift (Day)">
                            {item.shift}
                          </label>
                          - <span>{item.station}</span>
                        </div>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="listbtn">
                        <Link to="/ios-developer-2">
                          {item.showDetail}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <p>{item.detail}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
export default MyJobApplication;
