import MyJobApplication from "../../components/myJobApplication/MyJobApplication";

function MyJobApplicationPage() {
  return (
    <>
      <MyJobApplication />
    </>
  );
}
export default MyJobApplicationPage;
