import FavouriteJobs from "../../components/favouriteJobs/FavouriteJobs";

function FavouriteJobsPage() {
  return (
    <>
      <FavouriteJobs />
    </>
  );
}
export default FavouriteJobsPage;
