
function PageSearch() {
    return (
        <>
       <form action="https://hsalghanimdelivery.com/jobs" method="get">
  {/* Page Title start */}
  <div className="container">
    <div className="pageSearch">
      <div className="row">
        <div className="col-lg-7">
          <h3 className="mb-3">One million success job stories. <span>Start yours today.</span></h3>				
          <div className="searchform">
            <div className="input-group">
              <input type="text" name="search" id="jbsearch" defaultValue className="form-control" placeholder="Enter Skills or job title" autoComplete="off" />
              <button type="submit" className="btn"><i className="fas fa-search" /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Page Title end */}
</form>

        </>
    )
}
export default PageSearch