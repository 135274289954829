import { Link } from "react-router-dom";

function MyFollowings() {
  return (
    <>
      <div className="myads">
        <h3>My Followings</h3>
        <ul className="searchList">
          <li>
            <div className="row">
              <div className="col-md-8 col-sm-8">
                <div className="jobimg">
                  <img
                    src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                    alt="Abaris Softech Pvt Ltd"
                    title="Abaris Softech Pvt Ltd"
                  />
                </div>
                <div className="jobinfo">
                  <h3>
                    <a
                      href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                      title="Abaris Softech Pvt Ltd"
                    >
                      Abaris Softech Pvt Ltd
                    </a>
                  </h3>
                  <div className="location">
                    <label className="fulltime">New Delhi, Delhi, India</label>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="listbtn">
                  <Link to="/company/abaris-softech-pvt-ltd-9 ">View Details</Link>
                </div>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              fermentum condimentum mauris, non posuere urna consectetur quis.
              Suspendisse semper eu...
            </p>
          </li>
          {/* job end */}
        </ul>
      </div>
    </>
  );
}
export default MyFollowings;
