import ViewPublicProfile from "../../components/viewPublicProfile/ViewPublicProfile";

function ViewPublicProfilePage() {
  return (
    <>
      <ViewPublicProfile />
    </>
  );
}
export default ViewPublicProfilePage;
