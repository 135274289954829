function MyJobAlerts() {
  return (
    <>
      <div className="col-lg-9 col-sm-8">
        <div className="userdashbox">
          <h3>My Job Alerts</h3>
          <table className="table">
            <tbody>
              <tr>
                <th scope="col">Alert Title</th>
                <th scope="col">Created On</th>
                <th scope="col">Action</th>
              </tr>
              {/* job start */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default MyJobAlerts;
